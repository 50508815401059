import { twMerge } from "tailwind-merge";
import { SkeletonLoader } from "~/lib/ui/skeleton";
import { Suspense, useEffect, useRef, useState } from "react";

const IMAGE_NOT_FOUND_SRC = "/image_not_found.jpg";

/**
 * Lazy load an image
 * Shows a skeleton loader while the image is being loaded
 * Currently requires classname to be set to the desired height and width
 * Will be refactored at a later point
 * @param src - Source of the image
 * @param alt - Alt text for the image
 * @param className - Classname for the image
 * @param previewSize - Size of the preview skeleton loader using tailwind height options (default 16)
 * @param debug - To be removed, used for testing
 * @constructor
 */
export default function LazyImage({
  src,
  alt,
  className,
  previewSize = 16,
  onError,
}: {
  src?: string | null;
  alt?: string | null;
  className?: string;
  previewSize?: number;
  onError?: () => void;
}) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const boundingRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  // Create an observer to check if the image is in view
  // Unfortunately, in React, the native `loading="lazy"` attribute is not supported
  // At least not in conjunction with the `onLoad` event
  useEffect(() => {
    if (!boundingRef.current) return;

    const observer = new IntersectionObserver(onIntersection, { threshold: 0 });
    observer.observe(boundingRef.current);

    function onIntersection(entries: Array<IntersectionObserverEntry>) {
      const { isIntersecting } = entries[0];

      if (isIntersecting) {
        // is in view
        observer.disconnect();
      }

      setIsInView(isIntersecting);
    }

    // Clean up
    return () => {
      observer.disconnect();
    };
  }, [boundingRef.current, src]);

  if (!src) return null;

  const imageSrc = error ? IMAGE_NOT_FOUND_SRC : src;
  const imageClassName = error || loaded ? "block" : "hidden";

  const handleError = () => {
    setError(true);
    onError?.();
  };

  return (
    <>
      {(!loaded || !isInView) && (
        <div
          ref={boundingRef}
          className={twMerge(
            loaded ? "block h-auto w-auto" : "relative aspect-square",
            `h-${previewSize}`
          )}
        >
          <div className={twMerge("absolute left-0 top-0 h-full w-full")}>
            <SkeletonLoader template="image" className="h-full w-full" />
          </div>
        </div>
      )}
      <Suspense>
        <img
          ref={imageRef}
          src={isInView ? imageSrc : undefined}
          alt={alt ?? ""}
          className={twMerge(imageClassName, className)}
          onLoad={() => setLoaded(true)}
          onError={handleError}
        />
      </Suspense>
    </>
  );
}
