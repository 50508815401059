import { useQuery } from "@tanstack/react-query";
import { LineFeed } from "~/lib/ui/line-feed/line-feed";
import { useParams } from "react-router";
import { useGraphQL } from "~/lib/gql";
import { PriceFileDetailsPreviousFilesQuery } from "~/lib/gql/generated/graphql";
import { Icon } from "~/lib/ui";
import { useLocale } from "~/lib/utils/date";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { Badge } from "~/lib/ui/badge";
import { useTranslation } from "react-i18next";

const CACHE_PREVIOUS_PRICE_FILES = "previous-price-files";
type PriceFile =
  PriceFileDetailsPreviousFilesQuery["vendorProductPriceFile"]["pastPriceFiles"]["edges"][number]["node"];

export default function PreviousFilesSection() {
  const { priceFileId } = useParams<{ priceFileId: string }>();
  const sdk = useGraphQL();
  const { t } = useTranslation();

  const previousFilesQ = useQuery({
    queryKey: [CACHE_PREVIOUS_PRICE_FILES, priceFileId],
    queryFn: async () =>
      sdk.priceFileDetailsPreviousFiles({
        params: {
          id: priceFileId as string,
        },
      }),
  });

  const files = previousFilesQ.data?.vendorProductPriceFile?.pastPriceFiles.edges ?? [];
  return (
    <div>
      <div className="mb-2 text-gray-500">{`${t("common:earlier")} ${t("common:price_file", { count: 2 }).toLowerCase()}`}</div>
      <LineFeed
        label={`${t("common:earlier")} ${t("common:price_file", { count: 2 }).toLowerCase()}`}
        lines={files}
        renderLine={(line) => <PreviousFileLine line={line.node} />}
      />
    </div>
  );
}

const PreviousFileLine = ({ line }: { line: PriceFile }) => {
  const { format } = useLocale();
  return (
    <div className="group flex items-center justify-between gap-8">
      <div className="flex w-full items-center gap-3">
        <div className="rounded-full bg-primary p-1 text-white">
          <Icon name="file" className="h-5 w-5" />
        </div>
        <div className="flex grow justify-between gap-8">
          <span className="group-hover:underline">
            <OptionalLink to={"/procurement/price-files/" + line.id}>
              {line.originalFileName}
            </OptionalLink>
          </span>
          <Badge variant="gray" size="sm">
            {format(line.created, { excludeTime: true })}
          </Badge>
        </div>
      </div>
    </div>
  );
};
