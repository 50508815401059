import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { DNDUpload } from "~/lib/ui/d-n-d-upload";
import { getIcon, Icon, Label } from "~/lib/ui";
import { Popover } from "~/lib/ui/popover/popover";
import { useState } from "react";
import { MediaPreviewContainer } from "~/lib/ui/media/media-preview-container";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { UploadButton } from "~/lib/ui/buttons/upload-button";
import { IStandardFileUploadTypeEnum } from "@apacta/sdk";

export default function CreateStandardFileDialog({
  cacheKey,
  onClose,
}: {
  cacheKey: string;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const api = useAPI();
  const queryClient = useQueryClient();
  const [entity, setEntity] = useState<IStandardFileUploadTypeEnum>("quote");
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);

  const handleSubmit = async () => {
    await createStandardFileMutation.mutateAsync({ type: entity, file: filesToUpload[0] });
    await queryClient.invalidateQueries({ queryKey: [cacheKey] });
    onClose();
  };

  const handleRemoveFile = () => {
    setFilesToUpload([]);
  };

  const createStandardFileMutation = useMutation({
    mutationFn: (data: { type: IStandardFileUploadTypeEnum; file: File }) =>
      api.iStandardFileUpload({ type: data.type, file: data.file }),
  });

  return (
    <div>
      <DialogHeader
        title={t("common:create", {
          entity: t("common:standard_file", { count: 1 }).toLowerCase(),
        })}
        Icon={getIcon("file")}
      />
      <div className="mb-12 flex flex-col gap-4">
        <div>
          <Label>{t("common:availability")}</Label>
          <Popover
            triggerRender={() => (
              <div className="flex items-center gap-2 rounded-lg border bg-white px-3 py-2">
                <span>{t(`settings:standard_files.availability.${entity}`)}</span>
                <Icon name="chevronDown" className="h-5 w-5" />
              </div>
            )}
          >
            {(close) => (
              <div className="flex flex-col rounded-lg border bg-white shadow-md">
                <div
                  className="px-6 py-3 hover:bg-shade-100"
                  onClick={() => {
                    setEntity("quote");
                    close();
                  }}
                >
                  {t(`settings:standard_files.availability.quote`)}
                </div>
                <div
                  className="px-6 py-3 hover:bg-shade-100"
                  onClick={() => {
                    setEntity("estimate");
                    close();
                  }}
                >
                  {t(`settings:standard_files.availability.estimate`)}
                </div>
              </div>
            )}
          </Popover>
        </div>
        <div>
          <Label>{t("common:file", { count: 1 })}</Label>
          {filesToUpload.length ? (
            <div>
              {filesToUpload.map((file) => (
                <MediaPreviewContainer
                  key={file.name}
                  file={file}
                  onRemoveFile={handleRemoveFile}
                />
              ))}
            </div>
          ) : (
            <DNDUpload
              onSelected={(files) => setFilesToUpload(Array.from(files))}
              renderArea={({ onClick, isOver }) => (
                <div
                  className={twMerge(
                    isOver ? "border-hover text-hover" : "border-shade-400 text-shade-400",
                    "group flex h-72 items-center justify-center rounded-lg border-2 border-dashed transition-colors duration-300 ease-in-out"
                  )}
                >
                  <div className="flex flex-col items-center gap-4">
                    <div className="flex flex-col items-center gap-2">
                      <div>
                        <Icon
                          name="upload"
                          className={twMerge(
                            isOver ? "text-hover" : "text-primary",
                            "transition-color h-24 w-24 duration-300 ease-in-out"
                          )}
                        />
                      </div>
                      <div>{t("ui:dnd.drag_drop_files_upload")}</div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="w-24">
                        <hr className="border-t-1 border-shade-400" />
                      </div>
                      {t("common:or").toUpperCase()}
                      <div className="w-24">
                        <hr className="border-t-1 border-shade-400" />
                      </div>
                    </div>
                    <div>
                      <UploadButton variant="primary" onSelected={onClick}>
                        {t("ui:select_file", { count: 2 })}
                      </UploadButton>
                    </div>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:create", {
            entity: t("common:standard_file", { count: 1 }).toLowerCase(),
          }),
          onClick: () => handleSubmit(),
        }}
        onClose={onClose}
      />
    </div>
  );
}
