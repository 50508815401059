import StatusBadge, { StatusBadgeOption } from "~/lib/ui/badges/status-badge";
import { Order_Complaint_Status } from "~/lib/gql/generated/graphql";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useGraphQL } from "~/lib/gql";

export default function OrderComplaintStatus({
  defaultValue,
  orderComplaintId,
}: {
  defaultValue: string;
  orderComplaintId: string;
}) {
  const { t } = useTranslation();
  const sdk = useGraphQL();

  const statusChangeMutation = useMutation({
    mutationFn: ({ status }: { status: Order_Complaint_Status }) =>
      sdk.orderComplaintsUpdate({
        id: orderComplaintId,
        input: {
          status,
        },
      }),
  });

  const handleStatusChange = async (status: Order_Complaint_Status): Promise<boolean> => {
    return await statusChangeMutation
      .mutateAsync({ status })
      .then(() => true)
      .catch(() => false);
  };

  const statusOptions: Array<StatusBadgeOption> = [
    {
      id: "open",
      variant: "red",
      label: t("procurement:order_complaints.status.open"),
      onSelect: () => handleStatusChange(Order_Complaint_Status.Open),
    },
    {
      id: "pending",
      variant: "yellow",
      label: t("procurement:order_complaints.status.pending"),
      onSelect: () => handleStatusChange(Order_Complaint_Status.Pending),
    },
    {
      id: "solved",
      variant: "green",
      label: t("procurement:order_complaints.status.solved"),
      onSelect: () => handleStatusChange(Order_Complaint_Status.Solved),
    },
    {
      id: "ignored",
      variant: "gray",
      label: t("procurement:order_complaints.status.ignored"),
      onSelect: () => handleStatusChange(Order_Complaint_Status.Ignored),
    },
  ];
  return <StatusBadge options={statusOptions} selectedStatusId={defaultValue} />;
}
