import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useTranslation } from "react-i18next";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { toLocalDateString, useLocale } from "~/lib/utils/date";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useQuery } from "@tanstack/react-query";
import { useGraphQL } from "~/lib/gql";
import { CACHE_PROCUREMENT, ProcurementPageSearchParams } from "~/pages/procurement";
import {
  Pagination_Direction,
  Procurement_Analysis_List_Sort_Fields,
} from "~/lib/gql/generated/graphql";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { twMerge } from "tailwind-merge";
import { ProcurementProgressCell } from "~/pages/procurement/_cmp/table/procurement-progress-cell";
import { VendorCell } from "~/pages/procurement/_cmp/table/vendor-cell";

export const CACHE_PROCUREMENT_STATUS = "procurement-status";

export function ProcurementTable({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) {
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();
  const { t } = useTranslation();
  const { format } = useLocale();
  const sdk = useGraphQL();

  const tableState = useDataTableState({
    sorting: [
      {
        desc: !!Pagination_Direction.Desc,
        id: "Created",
      },
    ],
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_PROCUREMENT,
      dateFrom,
      dateTo,
      searchParams.companiesVendorIds,
      tableState.sortBy,
      tableState.sortingDirection,
    ],
    queryFn: () =>
      sdk.procurementIndex({
        analysisParams: {
          dateInterval: {
            from: toLocalDateString(dateFrom),
            to: toLocalDateString(dateTo),
          },
          companiesVendorIds: searchParams.companiesVendorIds,
          sortBy:
            Procurement_Analysis_List_Sort_Fields[
              tableState.sortBy as keyof typeof Procurement_Analysis_List_Sort_Fields
            ],
          direction:
            tableState.sortingDirection === "desc"
              ? Pagination_Direction.Desc
              : Pagination_Direction.Asc,
        },
        kpiParams: {
          dateInterval: {
            from: toLocalDateString(dateFrom),
            to: toLocalDateString(dateTo),
          },
          companiesVendorIds: searchParams.companiesVendorIds,
        },
      }),
  });

  const data = dataQ.data?.procurementAnalyses.edges || [];

  const columns = useDataColumns<(typeof data)[number]>((columnHelper) => [
    columnHelper.accessor("node.id", {
      header: t("common:name"),
      meta: {
        cellClassName: "align-top",
      },
      cell: ({ row, getValue }) => {
        const isCompleted = row.original.node.progress === 100;
        return (
          <OptionalLink to={isCompleted ? getValue() : undefined}>
            <span
              className={twMerge(
                "block shrink-0 whitespace-nowrap",
                isCompleted ? "font-semibold" : "italic"
              )}
            >{`${t("common:price_analysis", { count: 1 })} ${format(row.original.node.dateStart, { shortDate: true })} - ${format(row.original.node.dateEnd, { shortDate: true })}`}</span>
          </OptionalLink>
        );
      },
    }),
    columnHelper.accessor("node.created", {
      id: "Created",
      enableSorting: true,
      header: t("common:created_date"),
      cell: ({ row }) => <span>{format(row.original.node.created, { shortDate: true })}</span>,
      meta: {
        cellClassName: "text-center align-top",
        headerClassName: "text-center",
      },
    }),
    columnHelper.accessor("node.companiesVendors", {
      header: t("common:vendor", { count: 1 }),
      cell: ({ row }) => <VendorCell companiesVendors={row.original.node.companiesVendors} />,
      meta: {
        cellClassName: "max-w-md align-top",
      },
    }),
    columnHelper.accessor("node.progress", {
      header: t("common:status"),
      cell: ({ row }) => (
        <ProcurementProgressCell
          id={row.original.node.id}
          initialProgress={row.original.node.progress}
          status={row.original.node.status}
        />
      ),
      meta: {
        cellClassName: "w-52 text-right align-top",
      },
    }),
  ]);

  const table = useDataTable({
    columns,
    tableState,
    data,
    isLoading: dataQ.isLoading,
    getRowId: (edge) => edge.node.id,
  });

  return <DataTable table={table} />;
}
