import { ReactNode, useEffect, useRef, useState } from "react";
import { useMount } from "~/lib/lifecycle-helpers";
import { useTranslation } from "react-i18next";
import { IconProp } from "./types";
import { twMerge } from "tailwind-merge";
import { Icon } from "./icons/icon";

type ToastVariant = "success" | "warning" | "error";
export type ToastNotification = {
  id?: string; // Injected automatically
  title: string;
  description: string;
  variant?: ToastVariant;
  renderActions?: () => ReactNode | Array<ReactNode>;
  paused?: boolean;
  Icon?: IconProp;
  onDestroy?: () => void;
  onPause?: (v: boolean) => void;
  timeout?: number;
};

/**
 * @description Renders a toast notification that displays a message for a set amount of time
 * We are not using Radix because it REQUIRES a physical trigger to be wrapped in its own component
 * This is not described in the documentation and is a major drawback because it effectively makes it impossible to pause the timer on hover
 * @param title The title shown in the top of the notification
 * @param description The description shown below the title
 * @param renderActions Renders of elements to show below the description, used for buttons, links etc.
 * @param variant The variant determines the color scheme of the notification
 * @param paused Determines whether the timer should be running or not
 * @param timeout The amount of time the notification should be shown on screen in milliseconds
 * @param Icon The icon to show, using heroicons is advised
 * @param onDestroy Callback function to run when element has been completely hidden by transition
 * @param onPause Callback function to run when element has been paused by mouseover

 */
export function Toast({
  title,
  description,
  renderActions,
  variant = "success",
  Icon: IconP,
  onDestroy,
  onPause,
  timeout = 3000,
  paused,
}: ToastNotification) {
  const [open, setOpen] = useState<boolean>(true);
  const [remainingTime, setRemainingTime] = useState<number>(timeout);
  const [startTime, setStartTime] = useState<number>(new Date().getTime()); // used to calculate remaining time
  const refElement = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  let timer = 0;

  const handleDestroy = (): void => {
    refElement.current?.classList.add("hidden");
    onDestroy?.();
  };

  const removeTimeout = (): void => {
    setRemainingTime(remainingTime - (new Date().getTime() - startTime)); // set remaining time as we want pausing, not resetting
    clearTimeout(timer);
  };

  const createTimeout = (): void => {
    setStartTime(new Date().getTime()); // update start time to accurately be able to set remaining time
    timer = window.setTimeout(() => {
      setOpen(false);
    }, remainingTime);
  };

  const pauseTimeout = (): void => {
    onPause?.(true);
  };

  const resumeTimeout = (): void => {
    onPause?.(false);
  };

  useEffect(() => {
    if (paused) {
      removeTimeout();
    } else {
      createTimeout();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [paused]);

  useMount(() => {
    if (refElement.current) {
      const el = refElement.current as HTMLDivElement;

      el.addEventListener("mouseenter", pauseTimeout);
      el.addEventListener("mouseleave", resumeTimeout);

      return () => {
        clearTimeout(timer);
        el.removeEventListener("mouseenter", pauseTimeout);
        el.removeEventListener("mouseleave", resumeTimeout);
      };
    }
  });

  return (
    <>
      <div
        ref={refElement}
        className={twMerge(
          "flex w-full flex-col items-center space-y-4 sm:items-end",
          open ? "animate-enter-from-right" : "animate-exit-to-right"
        )}
        onAnimationEnd={() => (open ? undefined : handleDestroy())}
      >
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              {IconP ? (
                <div className="flex-shrink-0">
                  <IconP
                    className={twMerge(
                      "h-6 w-6",
                      variant === "success" && "text-green-400",
                      variant === "warning" && "text-yellow-400",
                      variant === "error" && "text-red-400"
                    )}
                    aria-hidden="true"
                  />
                </div>
              ) : null}

              <div className={twMerge("w-0 flex-1 pt-0.5 text-sm", IconP ? "ml-3" : "")}>
                <div className="font-medium text-zinc-700">{title}</div>
                <div className="mt-1 text-zinc-500">{description}</div>
                {renderActions ? (
                  <div
                    className={twMerge(
                      "mt-1",
                      variant === "success" && "text-green-900",
                      variant === "warning" && "text-yellow-900",
                      variant === "error" && "text-red-900"
                    )}
                  >
                    {renderActions?.()}
                  </div>
                ) : null}
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <span className="sr-only">{`${t("common:close")} ${t(
                    "common:toast"
                  ).toLowerCase()}`}</span>
                  <Icon name="close" size="small" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
