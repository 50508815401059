import { useGraphQL } from "~/lib/gql";
import { useQuery } from "@tanstack/react-query";
import { CSSProperties, useEffect, useState } from "react";
import colors from "tailwindcss/colors";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import { twMerge } from "tailwind-merge";
import { CACHE_PROCUREMENT_STATUS } from "~/pages/procurement/_cmp/table/procurement-table";
import { Button, getIcon } from "~/lib/ui";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import { Procurement_Analysis_Status } from "~/lib/gql/generated/graphql";
import { Badge } from "~/lib/ui/badge";

export function ProcurementProgressCell({
  id,
  status,
  initialProgress,
}: {
  id: string;
  status: Procurement_Analysis_Status;
  initialProgress: number;
}) {
  const [localStatus, setLocalStatus] = useState<Procurement_Analysis_Status>(status);
  const { t } = useTranslation();

  if (localStatus === Procurement_Analysis_Status.Failed) {
    return (
      <Badge size="sm" variant="red" className="w-48 justify-center">
        {t("procurement:analysis_failed")}
      </Badge>
    );
  }

  const isCompleted = localStatus === Procurement_Analysis_Status.Completed;

  return isCompleted ? (
    <Link to={id}>
      <Button
        as="span"
        size="small"
        Icon={getIcon("view")}
        variant="secondary"
      >{`${t("common:see")} ${t("common:price_analysis")}`}</Button>
    </Link>
  ) : (
    <ProcurementProgressBar
      procurementId={id}
      initialStatus={localStatus}
      initialProgress={initialProgress}
      onCompleted={() => setLocalStatus(Procurement_Analysis_Status.Completed)}
    />
  );
}

const ProcurementProgressBar = ({
  procurementId,
  initialStatus,
  initialProgress,
  onCompleted,
}: {
  procurementId: string;
  initialStatus: Procurement_Analysis_Status;
  initialProgress: number;
  onCompleted: (v: boolean) => void;
}) => {
  const sdk = useGraphQL();
  const { t } = useTranslation();

  const calculateRefetchInterval = (p: number, s: Procurement_Analysis_Status) => {
    if (s === Procurement_Analysis_Status.Pending) {
      return 5 * 1000; // 5 seconds if pending
    } else {
      return (60 * (1 - p / 100)).clamp(10, 60) * 1000; // Exponential backoff
    }
  };

  const [refetchInterval, setRefetchInterval] = useState<number>(
    calculateRefetchInterval(initialProgress, initialStatus)
  );

  const { data } = useQuery({
    queryKey: [CACHE_PROCUREMENT_STATUS, procurementId],
    queryFn: () => sdk.procurementIndexProgressCheck({ id: procurementId }),
    // Refetch every 60 seconds by default.
    // Decreases the interval as the progress approaches 100%.
    // Min: 10 seconds, Max: 60 seconds
    refetchInterval: refetchInterval,
  });

  const progress: number = data?.procurementAnalysis.progress ?? initialProgress;
  const status: Procurement_Analysis_Status = data?.procurementAnalysis.status ?? initialStatus;

  // Report completion to unmount the progress bar and thus the refetch interval
  useEffect(() => {
    if (progress === 100) {
      onCompleted(true);
    } else {
      setRefetchInterval(calculateRefetchInterval(progress, status));
    }
  }, [progress, status]);

  // Animated progress bar initial styles
  const progressStyles: CSSProperties = {
    backgroundSize: "24px 24px",
    backgroundImage: `linear-gradient(-45deg, ${colors["green"][500]} 25%, ${colors["green"][400]} 25%, ${colors["green"][400]} 50%, ${colors["green"][500]} 50%, ${colors["green"][500]} 75%, ${colors["green"][400]} 75%, ${colors["green"][400]})`,
  };

  const isPending = status === Procurement_Analysis_Status.Pending;

  return (
    <div>
      <ProgressPrimitive.Root
        className="relative h-6 w-48 overflow-hidden rounded-full bg-gray-300"
        style={{
          // Fix overflow clipping in Safari
          // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
          transform: "translateZ(0)",
        }}
        value={progress}
      >
        <ProgressPrimitive.Indicator
          className={twMerge(
            "ease-[cubic-bezier(0.65, 0, 0.35, 1)] relative size-full animate-progress transition-width duration-1000"
          )}
          style={{ width: `${progress}%`, ...progressStyles }}
        >
          <div
            className={twMerge(
              "flex h-full w-full items-center justify-center text-xs font-medium text-white",
              progress < 20 && "pl-3",
              progress < 5 && "text-unset pl-3"
            )}
            style={progress >= 5 ? { textShadow: "rgba(0, 0, 0, 1) 0px 1px 3px" } : undefined}
          >
            {isPending ? (
              <span className="w-full text-center">{t("common:pending")}</span>
            ) : (
              `${progress}%`
            )}
          </div>
        </ProgressPrimitive.Indicator>
      </ProgressPrimitive.Root>
    </div>
  );
};
