import { Button, getIcon, Icon } from "../..";
import { forwardRef, Ref } from "react";
import { Spinner } from "../../spinner";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useSelectionCombobox } from "../context";

/** If you override this, remember to pass all the props - otherwise Radix fails to inject  */
export const DefaultSingleSelectTrigger = forwardRef(function DefaultTriggerComponent<T>(
  props: object = {}, // Radix will inject something here that we don't care about
  ref: Ref<HTMLButtonElement>
) {
  const { t } = useTranslation();
  const {
    selectedItems,
    placeholder,
    labelFn,
    readonly,
    error,
    loading,
    open,
    icon,
    required,
    onClearSelection,
  } = useSelectionCombobox<T>();

  const renderedLabel = (() => {
    if (!selectedItems[0]) return <span className="text-shade-500">{placeholder}</span>;
    return labelFn(selectedItems[0]);
  })();

  const buttonHandle = !readonly ? (
    <Icon name="chevronUpDown" size="small" className="opacity-50" />
  ) : null;

  return (
    <Button
      ref={ref}
      variant="secondary"
      role="combobox"
      aria-expanded={open}
      className={twMerge(
        "h-10 w-full  px-3 py-2 text-left text-sm font-normal ",
        readonly && "text-black",
        error && "border-error",
        loading && "text-gray-400"
      )}
      disabled={loading || readonly}
      {...props}
    >
      <div className="flex w-full flex-row items-center justify-between gap-6">
        <>
          <div className="flex flex-row items-center">
            {icon && <Icon name={icon} size="small" className="mr-4" />}
            {!loading ? renderedLabel : t("common:loading")}
          </div>
          <div className="flex flex-row items-center">
            {selectedItems.length > 0 && !required && (
              <Button
                as="span"
                onClick={(e) => {
                  e.stopPropagation();
                  onClearSelection();
                }}
                className="cursor-pointer hover:text-hover"
                Icon={getIcon("clearSearch")}
                iconClassName="h-4 w-4"
              />
            )}
            {loading ? <Spinner className="h-5 w-5" /> : buttonHandle}
          </div>
        </>
      </div>
    </Button>
  );
});
