import { PageLayout } from "~/lib/ui/page-layout";
import { FormDtoWithJsonFormsData } from "@apacta/sdk";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { toLocalDateString } from "~/lib/utils/date";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useActivities } from "~/lib/activities/use-activities";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { getIcon } from "~/lib/ui";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { useState } from "react";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { getOffsetDate } from "~/lib/utils/date/date-utils";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { FormApprovalCell } from "~/lib/ui/table/cells/form-approval-cell";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";
import { EmployeeCell } from "~/lib/ui/table/cells/employee-cell";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { FormCell } from "~/lib/ui/table/cells/form-cell";
import { DataFilter } from "~/lib/ui/data-table/data-filter/data-filter";
import {
  APPROVED_FILTER,
  FORM_TEMPLATE_FILTER,
  PROJECT_FILTER,
  SINGLE_EMPLOYEE_FILTER,
} from "~/lib/ui/data-table/data-filter/filters";

type RegistrationFilterTypes = {
  approved?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  projectIds?: Array<string>;
  employeeId?: string;
  formTemplateIds?: Array<string>;
};

export const CACHE_FORMS = "forms"; // just forms!

export default function FormsPage() {
  const [pdfPreviewHref, setPdfPreviewHref] = useState<string | undefined>();

  const [searchParams, setSearchParams] = useTypedSearchParams<RegistrationFilterTypes>();

  const { t } = useTranslation();
  const api = useAPI();

  const { statuses } = useActivities();

  const getActivity = (id?: string): string | null => {
    if (!id) return null;
    return statuses.find((a) => a.id === id)?.name ?? null;
  };

  async function handleDeleteForm(id: string) {
    await api.deleteForm({ formId: id });
    await dataQuery.refetch();
  }

  const columns = useDataColumns<FormDtoWithJsonFormsData>((columnHelper) => [
    columnHelper.accessor("formDate", {
      header: t("common:date"),
      id: "form_date",
      enableSorting: true,
      cell: ({ row }) => <DateCell date={row.original.formDate} />,
    }),
    columnHelper.accessor("formTemplate.name", {
      header: t("planning:form", { count: 1 }),
      id: "formTemplate.name",
      cell: ({ row }) => (
        <FormCell
          formId={row.original.id}
          formTemplateName={row.original.template?.name}
          workDescription={row.original.workDescription}
          deleted={row.original.deleted ?? undefined}
          invoiced={row.original.isInvoiced}
        />
      ),
    }),

    columnHelper.accessor("user.fullName", {
      header: t("common:employee", { count: 1 }),
      id: "user.fullName",
      cell: ({ row }) => <EmployeeCell user={row.original.user!} />,
      meta: {
        skeletonType: "avatar",
      },
    }),
    columnHelper.accessor("project.fullName", {
      header: t("common:project", { count: 1 }),
      id: "project.fullName",
      cell: ({ row }) => <ProjectCell project={row.original.project!} />,
    }),
    columnHelper.accessor("activityId", {
      header: t("common:activity", { count: 1 }),
      id: "activityId",
      cell: ({ row }) => <div>{getActivity(row.original.activityId)}</div>,
    }),
    columnHelper.accessor("approved", {
      header: t("common:approved"),
      id: "approved",
      cell: ({ row }) => (
        <FormApprovalCell
          projectId={row.original.projectId}
          formId={row.original.id}
          value={!!row.original.approved}
        />
      ),
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("preview"),
              label: t("common:preview"),
              onClick: () => setPdfPreviewHref(row.original.linkToPdf),
            },
            {
              Icon: getIcon("edit"),
              label: t("common:edit"),
              href: `/projects/${row.original.projectId}/registrations/${row.original.id}`,
              target: "_blank",
            },
            {
              Icon: getIcon("delete"),
              label: t("forms:modal.delete.title"),
              confirm: {
                action: "delete",
                entity: "form",
              },
              onClick: () => handleDeleteForm(row.original.id),
            },
          ]}
        />
      ),
    }),
  ]);

  const tableState = useDataTableState();

  const dataQuery = useQuery({
    queryKey: [CACHE_FORMS, searchParams, tableState.state.pagination, tableState.state.sorting],
    refetchOnWindowFocus: true,
    queryFn: async () =>
      api.formsList({
        approved: searchParams.approved,
        dateFrom: toLocalDateString(searchParams.dateFrom),
        dateTo: toLocalDateString(searchParams.dateTo),
        projectId: searchParams.projectIds ?? undefined,
        userId: searchParams.employeeId,
        formTemplateId: searchParams.formTemplateIds ?? undefined,
        page: tableState.pageNumber,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        limit: tableState.state.pagination.pageSize,
      }),
  });

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQuery.data?.data,
      isLoading: dataQuery.isLoading,
      backendPagination: dataQuery.data?.pagination,
      getRowId: (row) => row.id,
    },
    {
      enableFilters: true,
    }
  );

  const handlePeriodChange = (from?: Date, to?: Date) => {
    setSearchParams("dateFrom", from ? toLocalDateString(from) : undefined);
    setSearchParams("dateTo", to ? toLocalDateString(to) : undefined);
  };

  const defaultFromDate = getOffsetDate(new Date(), -14);
  const defaultToDate = new Date();

  return (
    <PageLayout
      title={t("common:registration", { count: 2 })}
      renderDescription={() => (
        <DateRangePopover
          dateFrom={searchParams.dateFrom ? new Date(searchParams.dateFrom) : defaultFromDate}
          dateTo={searchParams.dateTo ? new Date(searchParams.dateTo) : defaultToDate}
          onChange={(range) => handlePeriodChange(range.from, range.to)}
          resetLabel={t("common:reset")}
          onReset={() => handlePeriodChange(defaultFromDate, defaultToDate)}
        />
      )}
    >
      <FullScreenFilePreview
        open={!!pdfPreviewHref}
        fileUrl={pdfPreviewHref}
        onClose={() => setPdfPreviewHref(undefined)}
      />
      <DataTable
        table={table}
        renderFilters={() => (
          <DataFilter
            filters={[
              APPROVED_FILTER,
              PROJECT_FILTER,
              SINGLE_EMPLOYEE_FILTER,
              FORM_TEMPLATE_FILTER,
            ]}
          />
        )}
      />
    </PageLayout>
  );
}
