/* eslint-disable */
import * as types from './graphql.js';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "query projectSelectionList($params: ProjectListParams) {\n  projects(params: $params) {\n    edges {\n      node {\n        id\n        projectNumber\n        fullName\n        name\n      }\n    }\n    pageInfo {\n      limit\n      hasNextPage\n      currentPage\n    }\n  }\n}": types.ProjectSelectionListDocument,
    "query createFixedInvoiceProjectDetails($projectId: ID!) {\n  project(id: $projectId) {\n    id\n    totalInvoicedAmount\n    completionPercentage\n    notInvoicedAmount\n    totalSalesPrice\n  }\n}": types.CreateFixedInvoiceProjectDetailsDocument,
    "query invoiceKPIData($id: ID!) {\n  invoice(id: $id) {\n    id\n    contributionRate\n    contributionMarginPerHour\n    costPriceTotal\n    grossSalesPriceTotal\n    hourUsageAmount\n    productUsageAmount\n  }\n}": types.InvoiceKpiDataDocument,
    "\n  query listProjectsTable($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n": types.ListProjectsTableDocument,
    "query listProjectsTest($params: ProjectListParams!) {\n  projects(params: $params) {\n    pageInfo {\n      limit\n      hasNextPage\n      hasPrevPage\n      currentPage\n    }\n    edges {\n      node {\n        id\n        name\n        created\n      }\n    }\n  }\n}": types.ListProjectsTestDocument,
    "\n  query listProjects($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n": types.ListProjectsDocument,
    "\n  mutation editProjectName($id: ID!, $name: String!) {\n    projectUpdate(id: $id, params: { name: $name }) {\n      id\n      projectNumber\n    }\n  }\n": types.EditProjectNameDocument,
    "query orderComplaintsDetail($id: ID!) {\n  orderComplaint(id: $id) {\n    id\n    expense {\n      id\n      company {\n        id\n        name\n        streetName\n        city {\n          name\n          zipCode\n        }\n        cvr\n        phone\n        phoneCountrycode\n        contactEmail\n        logoUrl {\n          small\n        }\n      }\n      supplierInvoiceNumber\n      vendor {\n        id\n        cvr\n        name\n        email\n        imageUrl\n      }\n    }\n    status\n    wrongPriceErrors {\n      id\n      productNumber\n      productName\n      quantity\n      paidPrice\n      priceFilePrice\n      priceDifference\n    }\n    unknownProductErrors {\n      id\n      productNumber\n      productName\n      quantity\n      paidPrice\n    }\n    pdfUrl\n  }\n}": types.OrderComplaintsDetailDocument,
    "query orderComplaintsIndex($params: OrderComplaintListParams!) {\n  orderComplaints(params: $params) {\n    edges {\n      node {\n        id\n        expense {\n          id\n          issuedDate\n          supplierInvoiceNumber\n          vendor {\n            id\n            name\n            imageUrl\n          }\n          project {\n            id\n            fullName\n          }\n        }\n        errorType\n        status\n        pdfUrl\n      }\n    }\n  }\n}": types.OrderComplaintsIndexDocument,
    "mutation orderComplaintsUpdate($id: ID!, $input: UpdateOrderComplaintInput!) {\n  updateOrderComplaint(id: $id, input: $input) {\n    id\n    status\n  }\n}": types.OrderComplaintsUpdateDocument,
    "query priceFileDetailKpiCards($params: VendorProductPriceFileKpiCardDataParams!) {\n  vendorProductPriceFileKpiCardData(params: $params) {\n    yourPriceDevelopment\n    globalPriceDevelopment\n    priceDecreasesCount\n    priceIncreasesCount\n  }\n}": types.PriceFileDetailKpiCardsDocument,
    "query priceFileDetailLines($params: VendorProductPriceFileLineListParams!) {\n  vendorProductPriceFileLines(params: $params) {\n    edges {\n      node {\n        id\n        consequence\n        currentPrice\n        previousPrice\n        priceChange\n        priceChangePercentage\n        quantityBought\n        vendorProduct {\n          id\n          name\n          productNumber\n          imageUrls {\n            thumbnail\n            original\n          }\n        }\n        vendorProductId\n        vendorProductPriceFileId\n      }\n    }\n  }\n}": types.PriceFileDetailLinesDocument,
    "query priceFileDetails($params: VendorProductPriceFileViewParams!) {\n  vendorProductPriceFile(params: $params) {\n    id\n    downloadUrl\n    companiesVendor {\n      id\n      vendorName\n      vendor {\n        name\n        imageUrl\n      }\n    }\n    originalFileName\n    created\n    previousPriceFile {\n      created\n    }\n  }\n}": types.PriceFileDetailsDocument,
    "query priceFileDetailsPreviousFiles($params: VendorProductPriceFileViewParams!) {\n  vendorProductPriceFile(params: $params) {\n    id\n    pastPriceFiles {\n      edges {\n        node {\n          id\n          originalFileName\n          created\n        }\n      }\n    }\n  }\n}": types.PriceFileDetailsPreviousFilesDocument,
    "query priceFilesIndex($params: VendorProductPriceFileListParams) {\n  vendorProductPriceFiles(params: $params) {\n    edges {\n      node {\n        id\n        companiesVendor {\n          id\n          vendorName\n          vendor {\n            name\n            imageUrl\n          }\n        }\n        originalFileName\n        created\n      }\n    }\n  }\n}": types.PriceFilesIndexDocument,
    "query procurementAnalysisLines($id: ID!, $linesParams: ProcurementAnalysisLineListParams) {\n  procurementAnalysis(id: $id) {\n    id\n    procurementAnalysisLines(params: $linesParams) {\n      id\n      name\n      companiesVendorId\n      countOfExpenses\n      countBought\n      totalAmountPaid\n      lastPricePaid\n      averageMarketPrice\n      minimumMarketPrice\n      diffBetweenAverageAndLastPrice\n      optimizationPotential\n      productImageUrls {\n        thumbnail\n        original\n      }\n    }\n  }\n}": types.ProcurementAnalysisLinesDocument,
    "mutation procurementAnalysisCreate($input: CreateProcurementAnalysisInput!) {\n  procurementAnalysisCreate(input: $input) {\n    id\n    progress\n  }\n}": types.ProcurementAnalysisCreateDocument,
    "query procurementDetail($id: ID!) {\n  procurementAnalysis(id: $id) {\n    id\n    created\n    dateStart\n    dateEnd\n    status\n    progress\n    companiesVendors {\n      id\n      vendorName\n      vendor {\n        name\n        imageUrl\n      }\n    }\n  }\n}": types.ProcurementDetailDocument,
    "query procurementIndexProgressCheck($id: ID!) {\n  procurementAnalysis(id: $id) {\n    id\n    progress\n    status\n  }\n}": types.ProcurementIndexProgressCheckDocument,
    "query procurementIndex($kpiParams: ProcurementKpiCardsParams, $analysisParams: ProcurementAnalysisListParams) {\n  procurementKpiCards(params: $kpiParams) {\n    priceUpdatesCount\n    totalMoneySpent\n    countOfProductsBought\n    averageTotalPricePerExpense\n  }\n  procurementAnalyses(params: $analysisParams) {\n    pageInfo {\n      hasPrevPage\n      hasNextPage\n      limit\n      currentPage\n    }\n    edges {\n      node {\n        id\n        created\n        dateStart\n        dateEnd\n        companiesVendors {\n          id\n          vendorName\n          vendor {\n            name\n          }\n        }\n        status\n        progress\n      }\n    }\n  }\n}": types.ProcurementIndexDocument,
    "query vendorSelection($params: CompaniesVendorListParams) {\n  companiesVendors(params: $params) {\n    edges {\n      node {\n        id\n        vendorName\n        vendor {\n          name\n        }\n      }\n    }\n  }\n}": types.VendorSelectionDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query projectSelectionList($params: ProjectListParams) {\n  projects(params: $params) {\n    edges {\n      node {\n        id\n        projectNumber\n        fullName\n        name\n      }\n    }\n    pageInfo {\n      limit\n      hasNextPage\n      currentPage\n    }\n  }\n}"): (typeof documents)["query projectSelectionList($params: ProjectListParams) {\n  projects(params: $params) {\n    edges {\n      node {\n        id\n        projectNumber\n        fullName\n        name\n      }\n    }\n    pageInfo {\n      limit\n      hasNextPage\n      currentPage\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query createFixedInvoiceProjectDetails($projectId: ID!) {\n  project(id: $projectId) {\n    id\n    totalInvoicedAmount\n    completionPercentage\n    notInvoicedAmount\n    totalSalesPrice\n  }\n}"): (typeof documents)["query createFixedInvoiceProjectDetails($projectId: ID!) {\n  project(id: $projectId) {\n    id\n    totalInvoicedAmount\n    completionPercentage\n    notInvoicedAmount\n    totalSalesPrice\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query invoiceKPIData($id: ID!) {\n  invoice(id: $id) {\n    id\n    contributionRate\n    contributionMarginPerHour\n    costPriceTotal\n    grossSalesPriceTotal\n    hourUsageAmount\n    productUsageAmount\n  }\n}"): (typeof documents)["query invoiceKPIData($id: ID!) {\n  invoice(id: $id) {\n    id\n    contributionRate\n    contributionMarginPerHour\n    costPriceTotal\n    grossSalesPriceTotal\n    hourUsageAmount\n    productUsageAmount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listProjectsTable($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listProjectsTable($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query listProjectsTest($params: ProjectListParams!) {\n  projects(params: $params) {\n    pageInfo {\n      limit\n      hasNextPage\n      hasPrevPage\n      currentPage\n    }\n    edges {\n      node {\n        id\n        name\n        created\n      }\n    }\n  }\n}"): (typeof documents)["query listProjectsTest($params: ProjectListParams!) {\n  projects(params: $params) {\n    pageInfo {\n      limit\n      hasNextPage\n      hasPrevPage\n      currentPage\n    }\n    edges {\n      node {\n        id\n        name\n        created\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listProjects($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listProjects($params: ProjectListParams!) {\n    projects(params: $params) {\n      pageInfo {\n        limit\n        hasNextPage\n        hasPrevPage\n        currentPage\n      }\n      edges {\n        node {\n          id\n          name\n          created\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation editProjectName($id: ID!, $name: String!) {\n    projectUpdate(id: $id, params: { name: $name }) {\n      id\n      projectNumber\n    }\n  }\n"): (typeof documents)["\n  mutation editProjectName($id: ID!, $name: String!) {\n    projectUpdate(id: $id, params: { name: $name }) {\n      id\n      projectNumber\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query orderComplaintsDetail($id: ID!) {\n  orderComplaint(id: $id) {\n    id\n    expense {\n      id\n      company {\n        id\n        name\n        streetName\n        city {\n          name\n          zipCode\n        }\n        cvr\n        phone\n        phoneCountrycode\n        contactEmail\n        logoUrl {\n          small\n        }\n      }\n      supplierInvoiceNumber\n      vendor {\n        id\n        cvr\n        name\n        email\n        imageUrl\n      }\n    }\n    status\n    wrongPriceErrors {\n      id\n      productNumber\n      productName\n      quantity\n      paidPrice\n      priceFilePrice\n      priceDifference\n    }\n    unknownProductErrors {\n      id\n      productNumber\n      productName\n      quantity\n      paidPrice\n    }\n    pdfUrl\n  }\n}"): (typeof documents)["query orderComplaintsDetail($id: ID!) {\n  orderComplaint(id: $id) {\n    id\n    expense {\n      id\n      company {\n        id\n        name\n        streetName\n        city {\n          name\n          zipCode\n        }\n        cvr\n        phone\n        phoneCountrycode\n        contactEmail\n        logoUrl {\n          small\n        }\n      }\n      supplierInvoiceNumber\n      vendor {\n        id\n        cvr\n        name\n        email\n        imageUrl\n      }\n    }\n    status\n    wrongPriceErrors {\n      id\n      productNumber\n      productName\n      quantity\n      paidPrice\n      priceFilePrice\n      priceDifference\n    }\n    unknownProductErrors {\n      id\n      productNumber\n      productName\n      quantity\n      paidPrice\n    }\n    pdfUrl\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query orderComplaintsIndex($params: OrderComplaintListParams!) {\n  orderComplaints(params: $params) {\n    edges {\n      node {\n        id\n        expense {\n          id\n          issuedDate\n          supplierInvoiceNumber\n          vendor {\n            id\n            name\n            imageUrl\n          }\n          project {\n            id\n            fullName\n          }\n        }\n        errorType\n        status\n        pdfUrl\n      }\n    }\n  }\n}"): (typeof documents)["query orderComplaintsIndex($params: OrderComplaintListParams!) {\n  orderComplaints(params: $params) {\n    edges {\n      node {\n        id\n        expense {\n          id\n          issuedDate\n          supplierInvoiceNumber\n          vendor {\n            id\n            name\n            imageUrl\n          }\n          project {\n            id\n            fullName\n          }\n        }\n        errorType\n        status\n        pdfUrl\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "mutation orderComplaintsUpdate($id: ID!, $input: UpdateOrderComplaintInput!) {\n  updateOrderComplaint(id: $id, input: $input) {\n    id\n    status\n  }\n}"): (typeof documents)["mutation orderComplaintsUpdate($id: ID!, $input: UpdateOrderComplaintInput!) {\n  updateOrderComplaint(id: $id, input: $input) {\n    id\n    status\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query priceFileDetailKpiCards($params: VendorProductPriceFileKpiCardDataParams!) {\n  vendorProductPriceFileKpiCardData(params: $params) {\n    yourPriceDevelopment\n    globalPriceDevelopment\n    priceDecreasesCount\n    priceIncreasesCount\n  }\n}"): (typeof documents)["query priceFileDetailKpiCards($params: VendorProductPriceFileKpiCardDataParams!) {\n  vendorProductPriceFileKpiCardData(params: $params) {\n    yourPriceDevelopment\n    globalPriceDevelopment\n    priceDecreasesCount\n    priceIncreasesCount\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query priceFileDetailLines($params: VendorProductPriceFileLineListParams!) {\n  vendorProductPriceFileLines(params: $params) {\n    edges {\n      node {\n        id\n        consequence\n        currentPrice\n        previousPrice\n        priceChange\n        priceChangePercentage\n        quantityBought\n        vendorProduct {\n          id\n          name\n          productNumber\n          imageUrls {\n            thumbnail\n            original\n          }\n        }\n        vendorProductId\n        vendorProductPriceFileId\n      }\n    }\n  }\n}"): (typeof documents)["query priceFileDetailLines($params: VendorProductPriceFileLineListParams!) {\n  vendorProductPriceFileLines(params: $params) {\n    edges {\n      node {\n        id\n        consequence\n        currentPrice\n        previousPrice\n        priceChange\n        priceChangePercentage\n        quantityBought\n        vendorProduct {\n          id\n          name\n          productNumber\n          imageUrls {\n            thumbnail\n            original\n          }\n        }\n        vendorProductId\n        vendorProductPriceFileId\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query priceFileDetails($params: VendorProductPriceFileViewParams!) {\n  vendorProductPriceFile(params: $params) {\n    id\n    downloadUrl\n    companiesVendor {\n      id\n      vendorName\n      vendor {\n        name\n        imageUrl\n      }\n    }\n    originalFileName\n    created\n    previousPriceFile {\n      created\n    }\n  }\n}"): (typeof documents)["query priceFileDetails($params: VendorProductPriceFileViewParams!) {\n  vendorProductPriceFile(params: $params) {\n    id\n    downloadUrl\n    companiesVendor {\n      id\n      vendorName\n      vendor {\n        name\n        imageUrl\n      }\n    }\n    originalFileName\n    created\n    previousPriceFile {\n      created\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query priceFileDetailsPreviousFiles($params: VendorProductPriceFileViewParams!) {\n  vendorProductPriceFile(params: $params) {\n    id\n    pastPriceFiles {\n      edges {\n        node {\n          id\n          originalFileName\n          created\n        }\n      }\n    }\n  }\n}"): (typeof documents)["query priceFileDetailsPreviousFiles($params: VendorProductPriceFileViewParams!) {\n  vendorProductPriceFile(params: $params) {\n    id\n    pastPriceFiles {\n      edges {\n        node {\n          id\n          originalFileName\n          created\n        }\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query priceFilesIndex($params: VendorProductPriceFileListParams) {\n  vendorProductPriceFiles(params: $params) {\n    edges {\n      node {\n        id\n        companiesVendor {\n          id\n          vendorName\n          vendor {\n            name\n            imageUrl\n          }\n        }\n        originalFileName\n        created\n      }\n    }\n  }\n}"): (typeof documents)["query priceFilesIndex($params: VendorProductPriceFileListParams) {\n  vendorProductPriceFiles(params: $params) {\n    edges {\n      node {\n        id\n        companiesVendor {\n          id\n          vendorName\n          vendor {\n            name\n            imageUrl\n          }\n        }\n        originalFileName\n        created\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query procurementAnalysisLines($id: ID!, $linesParams: ProcurementAnalysisLineListParams) {\n  procurementAnalysis(id: $id) {\n    id\n    procurementAnalysisLines(params: $linesParams) {\n      id\n      name\n      companiesVendorId\n      countOfExpenses\n      countBought\n      totalAmountPaid\n      lastPricePaid\n      averageMarketPrice\n      minimumMarketPrice\n      diffBetweenAverageAndLastPrice\n      optimizationPotential\n      productImageUrls {\n        thumbnail\n        original\n      }\n    }\n  }\n}"): (typeof documents)["query procurementAnalysisLines($id: ID!, $linesParams: ProcurementAnalysisLineListParams) {\n  procurementAnalysis(id: $id) {\n    id\n    procurementAnalysisLines(params: $linesParams) {\n      id\n      name\n      companiesVendorId\n      countOfExpenses\n      countBought\n      totalAmountPaid\n      lastPricePaid\n      averageMarketPrice\n      minimumMarketPrice\n      diffBetweenAverageAndLastPrice\n      optimizationPotential\n      productImageUrls {\n        thumbnail\n        original\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "mutation procurementAnalysisCreate($input: CreateProcurementAnalysisInput!) {\n  procurementAnalysisCreate(input: $input) {\n    id\n    progress\n  }\n}"): (typeof documents)["mutation procurementAnalysisCreate($input: CreateProcurementAnalysisInput!) {\n  procurementAnalysisCreate(input: $input) {\n    id\n    progress\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query procurementDetail($id: ID!) {\n  procurementAnalysis(id: $id) {\n    id\n    created\n    dateStart\n    dateEnd\n    status\n    progress\n    companiesVendors {\n      id\n      vendorName\n      vendor {\n        name\n        imageUrl\n      }\n    }\n  }\n}"): (typeof documents)["query procurementDetail($id: ID!) {\n  procurementAnalysis(id: $id) {\n    id\n    created\n    dateStart\n    dateEnd\n    status\n    progress\n    companiesVendors {\n      id\n      vendorName\n      vendor {\n        name\n        imageUrl\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query procurementIndexProgressCheck($id: ID!) {\n  procurementAnalysis(id: $id) {\n    id\n    progress\n    status\n  }\n}"): (typeof documents)["query procurementIndexProgressCheck($id: ID!) {\n  procurementAnalysis(id: $id) {\n    id\n    progress\n    status\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query procurementIndex($kpiParams: ProcurementKpiCardsParams, $analysisParams: ProcurementAnalysisListParams) {\n  procurementKpiCards(params: $kpiParams) {\n    priceUpdatesCount\n    totalMoneySpent\n    countOfProductsBought\n    averageTotalPricePerExpense\n  }\n  procurementAnalyses(params: $analysisParams) {\n    pageInfo {\n      hasPrevPage\n      hasNextPage\n      limit\n      currentPage\n    }\n    edges {\n      node {\n        id\n        created\n        dateStart\n        dateEnd\n        companiesVendors {\n          id\n          vendorName\n          vendor {\n            name\n          }\n        }\n        status\n        progress\n      }\n    }\n  }\n}"): (typeof documents)["query procurementIndex($kpiParams: ProcurementKpiCardsParams, $analysisParams: ProcurementAnalysisListParams) {\n  procurementKpiCards(params: $kpiParams) {\n    priceUpdatesCount\n    totalMoneySpent\n    countOfProductsBought\n    averageTotalPricePerExpense\n  }\n  procurementAnalyses(params: $analysisParams) {\n    pageInfo {\n      hasPrevPage\n      hasNextPage\n      limit\n      currentPage\n    }\n    edges {\n      node {\n        id\n        created\n        dateStart\n        dateEnd\n        companiesVendors {\n          id\n          vendorName\n          vendor {\n            name\n          }\n        }\n        status\n        progress\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "query vendorSelection($params: CompaniesVendorListParams) {\n  companiesVendors(params: $params) {\n    edges {\n      node {\n        id\n        vendorName\n        vendor {\n          name\n        }\n      }\n    }\n  }\n}"): (typeof documents)["query vendorSelection($params: CompaniesVendorListParams) {\n  companiesVendors(params: $params) {\n    edges {\n      node {\n        id\n        vendorName\n        vendor {\n          name\n        }\n      }\n    }\n  }\n}"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;