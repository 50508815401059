import { IListProjects200Response } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useMe } from "~/lib/auth/use-me";
import { getIcon } from "~/lib/ui";
import { useDataColumns } from "~/lib/ui/data-table";
import { linkToProject } from "~/lib/utils";
import { CACHE_PROJECTS } from "..";
import ProjectStatusChange from "./project-status-change";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";
import { CustomerCell } from "~/lib/ui/table/cells/customer-cell";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { CurrencyCell } from "~/lib/ui/table/cells/currency-cell";

export function useProjectTableColumns() {
  const { companySettings } = useMe();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useAPI();

  const duplicateM = useMutation({
    mutationFn: async (projectId: string) => {
      const res = await api.duplicateProject({ projectId: projectId });
      await invalidateProjectIndex();
      return res;
    },
  });

  const deleteM = useMutation({
    mutationFn: async (projectId: string) => {
      const res = await api.iDeleteProject({ projectId });
      await invalidateProjectIndex();
      return res;
    },
  });

  async function invalidateProjectIndex() {
    return queryClient.invalidateQueries({
      queryKey: [CACHE_PROJECTS, "index"],
    });
  }

  const columns = useDataColumns<IListProjects200Response["data"][number]>((columnHelper) => [
    columnHelper.accessor("id", {
      id: "project_number",
      header: t("projects:fields.number"),
      enableSorting: true,
      cell: ({ row }) => (
        <span className="block font-semibold ">#{row.original.projectNumber}</span>
      ),
      meta: {
        href: (project) => linkToProject(project.id),
        className: !companySettings.projectNumbers ? "hidden" : "",
      },
    }),
    columnHelper.accessor("name", {
      enableSorting: true,
      header: t("common:name"),
      cell: ({ row }) => <ProjectCell project={row.original} primary />,
      meta: {
        cellClassName: "max-w-sm",
      },
    }),
    columnHelper.accessor("contact", {
      id: "customer",
      header: t("common:customer", { count: 1 }),
      cell: ({ row }) => (
        <CustomerCell customer={row.original.contact} address={row.original.streetName} />
      ),
      meta: {
        skeletonType: "avatar",
      },
    }),
    columnHelper.accessor("firstActivityDate", {
      enableSorting: true,
      id: "first_activity_date",
      header: t("projects:fields.first_activity", { defaultValue: "First activity" }),
      cell: ({ row }) => <DateCell date={row.original.firstActivityDate} />,
    }),
    columnHelper.accessor("notInvoicedAmount", {
      id: "not_invoiced_amount",
      enableSorting: true,
      header: t("projects:fields.uninvoiced_amount"),
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => <CurrencyCell value={row.original.notInvoicedAmount} warnOnPositive />,
    }),
    columnHelper.accessor("projectStatusId", {
      id: "project_status_id",
      enableSorting: true,
      header: t("common:status"),
      cell: ({ row }) => (
        <ProjectStatusChange
          project={row.original}
          initialStatusId={row.original.projectStatusId}
        />
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("edit"),
              label: t("common:edit"),
              href: linkToProject(row.original.id, { subPage: "data" }),
            },
            {
              Icon: getIcon("duplicate"),
              label: t("common:duplicate"),
              confirm: {
                action: "generic",
              },
              onClick: () => duplicateM.mutateAsync(row.original.id),
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),

              confirm: {
                entity: "project",
                action: "delete",
              },
              onClick: () => deleteM.mutateAsync(row.original.id),
            },
          ]}
        />
      ),
    }),
  ]);
  return columns;
}
