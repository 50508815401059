import { flexRender, Row, RowData, Table } from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";
import { TablePagination } from "./table-pagination";
import { Button, getIcon, Icon } from "..";
import { Checkbox } from "../form-elements";
import { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { TableTopBar } from "./table-top-bar";
import { ButtonAction } from "../action-buttons";
import { PaginationDetails } from "@apacta/sdk";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { TableSkeletonRows } from "./table-skeleton-rows";
import { TableEmptyPlaceHolder } from "./table-empty-placeholder";
import { Dropdown } from "../dropdown";
import { TableColumnVisibilityFragment } from "./table-column-visibility-fragment";

// Callback extends the generic RowData type
type RowSelectionActionFn<T extends RowData> = <K extends T = T>(
  selectedRows: Array<Row<K>>
) => Array<ButtonAction>;

type Props<T extends RowData> = {
  table: Table<T>;
  renderExpandedRow?: ({
    row,
  }: {
    row: Row<T>;
    onHasPendingChanges: (hasPendingChanges: boolean) => number;
  }) => ReactNode;
  renderFilters?: () => ReactNode;
  /** Actions available for row selection. Pass array or function to array
   * Note: Actions for table columns are configured in the column definition.
   */
  selectionActions?: Array<ButtonAction> | RowSelectionActionFn<T>;
  /** Pagination from the server - helps if we don't know the page-size */
  paginationDetails?: PaginationDetails;
  /** Search Overrides */
  search?: {
    placeholder?: string;
  };
  /** Hide pagination */
  hidePagination?: boolean;

  /** Loading state is handled by the table's meta object. Do not add it here. */
};

/**
 * A rendering component for an instance of React Table or useDataTable()
 *
 * Ideal for pure data without API calls or custom behaviour. See readme for details.
 */
export function DataTable<T extends RowData>({
  table,
  renderExpandedRow,
  selectionActions,
  renderFilters,
  paginationDetails,
  search,
  hidePagination,
}: Props<T>) {
  const { pagination } = table.getState();
  const topOfTable = useRef<HTMLDivElement>(null);
  const showLeftActionArea = table.options.enableExpanding || !!table.options.enableRowSelection;
  const [pendingRowChanges, setPendingRowChanges] = useState<Set<string>>(new Set());

  // If the table data is empty, but the page is above 1, we should go back to the previous page
  useEffect(() => {
    if (!pagination) return;
    if (table.options.meta?.isLoading) return;
    if (table.options.data.length === 0 && pagination.pageIndex > 0) {
      console.warn("Table data was empty, going back automatically...");
      table.setPageIndex(pagination.pageIndex - 1);
    }
  }, [table.options.data]);

  // Adjust pagination if it differs from server response
  useEffect(() => {
    if (!paginationDetails?.limit) return;
    if (!table.getState().pagination) return;
    if (table.getState().pagination.pageSize !== paginationDetails.limit) {
      table.setPageSize(paginationDetails.limit);
    }
  }, [paginationDetails]);

  const showExpandAllRowsButton =
    table.options.enableExpanding &&
    table.getCanSomeRowsExpand() &&
    !table.options.meta?.singleRowExpansion;

  const isLoading = table.options.meta?.isLoading;

  function handlePendingChanges(row: Row<T>, hasChanges: boolean): number {
    // Add row to set if it has changes, remove it if it doesnt. Then return a number of rows with changes
    let newCount = 0;
    setPendingRowChanges((prev) => {
      if (hasChanges) {
        prev.add(row.id);
      } else {
        prev.delete(row.id);
      }
      prev.size > 0 && (newCount = prev.size);
      return new Set(prev);
    });
    return newCount;
  }

  return (
    <div className="flex flex-col gap-6">
      <div ref={topOfTable}>
        <TableTopBar
          table={table}
          selectionActions={selectionActions}
          renderFilters={renderFilters}
          placeholder={search?.placeholder}
        />
      </div>
      <div className="apacta-table">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {showLeftActionArea && (
                    <th className="w-10">
                      <div className="flex flex-row justify-start">
                        {table.options.enableRowSelection && (
                          <Checkbox
                            checked={table.getIsAllRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                          />
                        )}
                        {showExpandAllRowsButton && (
                          <button onClick={table.getToggleAllRowsExpandedHandler()}>
                            <Icon
                              name={table.getIsAllRowsExpanded() ? "retractRow" : "expandRow"}
                              size="small"
                            />
                          </button>
                        )}
                      </div>
                    </th>
                  )}
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={twMerge(
                        "text-nowrap",
                        header.column.getCanSort() && "cursor-pointer",
                        header.column.columnDef.meta?.className,
                        header.column.columnDef.meta?.headerClassName
                      )}
                      {...(header.column.getCanSort()
                        ? { onClick: header.column.getToggleSortingHandler() }
                        : {})}
                    >
                      {header.column.columnDef.id === "actions" && (
                        <Dropdown
                          options={{
                            trigger: {
                              asChild: true,
                            },
                          }}
                          trigger={
                            <Button Icon={getIcon("menuKebab")} variant="secondary" size="small" />
                          }
                        >
                          <TableColumnVisibilityFragment table={table} />
                        </Dropdown>
                      )}
                      <div className={twMerge("inline-flex flex-row")}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && (
                          <>
                            {header.column.getIsSorted() === "asc" && (
                              <Icon name="chevronDown" className="ml-2 h-auto w-4" />
                            )}
                            {header.column.getIsSorted() === "desc" && (
                              <Icon name="chevronUp" className="ml-2 h-auto w-4" />
                            )}
                          </>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            <TableSkeletonRows table={table} showLeftActionArea={showLeftActionArea} />
            {!isLoading &&
              table.getRowModel().rows.map((row, idx) => {
                const enableRowExpansion =
                  table.options.enableExpanding && row.getCanExpand() && renderExpandedRow;
                return (
                  <Fragment key={`${row.id ?? `row-${idx}`}`}>
                    <tr
                      key={row.id}
                      className={twMerge(
                        idx % 2 ? "bg-shade-50" : "bg-white",
                        pendingRowChanges.has(row.id) && "bg-yellow-50"
                      )}
                    >
                      {showLeftActionArea && (
                        <td className="">
                          <div className="flex flex-row justify-start">
                            {table.options.enableRowSelection && (
                              <Checkbox
                                checked={row.getIsSelected()}
                                onChange={row.getToggleSelectedHandler()}
                                disabled={!row.getCanSelect()}
                              />
                            )}
                            {table.options.enableExpanding && row.getCanExpand() && (
                              <button onClick={row.getToggleExpandedHandler()}>
                                <Icon
                                  name={row.getIsExpanded() ? "retractRow" : "expandRow"}
                                  size="small"
                                />
                              </button>
                            )}
                          </div>
                        </td>
                      )}
                      {row.getVisibleCells().map((cell) => {
                        const href = cell.column.columnDef.meta?.href;
                        const newTab = cell.column.columnDef.meta?.openLinkInNewTab;
                        return (
                          <td
                            key={cell.id}
                            className={twMerge(
                              cell.column.columnDef.meta?.className,
                              cell.column.columnDef.meta?.cellClassName
                            )}
                          >
                            <OptionalLink
                              to={href?.(row.original)}
                              openInNewTab={newTab}
                              className="h-full w-full"
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </OptionalLink>
                          </td>
                        );
                      })}
                    </tr>
                    {enableRowExpansion && !table.options?.meta?.isLoading && (
                      <tr
                        className={twMerge(
                          "hidden animate-slide-down",
                          row.getIsExpanded() && "table-row"
                        )}
                      >
                        <td colSpan={table.getVisibleFlatColumns().length + 1}>
                          {renderExpandedRow?.({
                            row,
                            onHasPendingChanges: (hasChanges) =>
                              handlePendingChanges(row, hasChanges),
                          })}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            <TableEmptyPlaceHolder table={table} showLeftActionArea={showLeftActionArea} />

            {/* Footer rows */}
            {table.getFooterGroups().map((footerGroup) => {
              const footers = footerGroup.headers
                .map((h) => h.column.columnDef.footer)
                .filter(Boolean);

              // Skip showing footers if there are none defined
              if (footers.length === 0) {
                return null;
              }
              return (
                <tr key={footerGroup.id}>
                  <>
                    {showLeftActionArea && <th></th>}
                    {footerGroup.headers.map((footer) => (
                      <th
                        key={footer.id}
                        colSpan={footer.colSpan}
                        className={twMerge(
                          footer.column.columnDef.meta?.className,
                          footer.column.columnDef.meta?.footerClassName
                        )}
                      >
                        {footer.isPlaceholder
                          ? null
                          : flexRender(footer.column.columnDef.footer, footer.getContext())}
                      </th>
                    ))}
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && !hidePagination && (
        <div>
          <TablePagination table={table} pagination={pagination} />
        </div>
      )}
    </div>
  );
}
