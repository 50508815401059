import { z } from "zod";
import { FieldMeta, FormReducerState, SchemaMap } from "../types";
import { matchingArrays } from "~/lib/utils/object/matching-arrays";
import { isEqual } from "lodash";

export function setRootValues<Schema extends SchemaMap>(
  state: FormReducerState<Schema>
): FormReducerState<Schema> {
  let isAnyPending = false;
  let isAllValid = true;
  let isAnyModified = false;
  const modifiedFields: Array<string> = [];
  const invalidFields: Array<string> = [];
  // Set values received
  Object.keys(state.meta).forEach((field) => {
    if (state.meta[field].status === "pending") {
      isAnyPending = true;
      isAllValid = false;
      invalidFields.push(field);
    }
    if (state.meta[field].status === "invalid") {
      isAllValid = false;
      invalidFields.push(field);
    }

    if (state.meta[field].value !== state.meta[field].initialValue) {
      if (Array.isArray(state.meta[field].value) && Array.isArray(state.meta[field].initialValue)) {
        if (!matchingArrays(state.meta[field].value, state.meta[field].initialValue)) {
          isAnyModified = true;
          modifiedFields.push(field);
        }
      } else if (typeof state.meta[field].value === "object") {
        if (!isEqual(state.meta[field].value, state.meta[field].initialValue)) {
          isAnyModified = true;
          modifiedFields.push(field);
        }
      } else {
        isAnyModified = true;
        modifiedFields.push(field);
      }
    }
  });

  return {
    ...state,
    isPending: isAnyPending,
    isValid: isAllValid,
    isModified: isAnyModified,
    modifiedFields,
    invalidFields,
  } satisfies FormReducerState<Schema>;
}

export const setSingleField = <S extends SchemaMap, K extends keyof S>(
  state: FormReducerState<S>,
  field: K,
  fieldValue: FieldMeta<S, K>,
  validate = false
): FormReducerState<S> => {
  const newFieldValue = {
    ...fieldValue,
    modified: fieldValue.initialValue !== fieldValue.value,
  } as FieldMeta<S, K>;

  if (validate) {
    let errors: Array<string> = [];
    const zRes = state.schema[field].safeParse(fieldValue.value);
    if (!zRes.success) {
      errors = [...zRes.error.issues.map((i) => i.message)];
      if (newFieldValue.touched) {
        newFieldValue.errors = errors;
      }

      newFieldValue.status = "invalid";
    } else {
      newFieldValue.errors = [];
      newFieldValue.status = "valid";
    }
  }

  return {
    ...state,
    meta: {
      ...state.meta,
      [field]: {
        ...newFieldValue,
      },
    },
  };
};
/* Disable eslint for unsafe assignment as we do not control the types from Zod
 * and making the changes necessary to do so will take far too long.
 * Zod value will be of type `any` */
/* eslint-disable @typescript-eslint/no-unsafe-assignment*/
export function setValuesReducer<Schema extends SchemaMap>(
  state: FormReducerState<Schema>,
  action: {
    type: "setValues";
    values: {
      [FK in keyof Schema]?: z.TypeOf<Schema[FK]> | undefined;
    };
    initial?: boolean;
    reset: boolean;
  }
): FormReducerState<Schema> {
  let newState = { ...state };

  // Set new values and validate
  Object.entries(action.values).forEach(([key, value]) => {
    const zRes = newState.schema[key].safeParse(value);
    let errors: Array<string> = [];
    if (!zRes.success) {
      errors = [...zRes.error.issues.map((i) => i.message)];
    }

    newState = setSingleField(newState, key, {
      ...newState.meta[key as string],
      touched: !action.reset,
      value,
      initialValue: action.initial ? value : newState.meta[key as string].initialValue,
      errors: action.reset ? [] : errors,
      status: errors.length > 0 ? "invalid" : "valid",
    });
  });

  return newState;
}
