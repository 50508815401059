import MimeIcon from "../icons/mime-icon";
import { PlayIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import LazyImage from "~/lib/ui/lazy-image";

/**
 * Media preview component
 * Used to display a preview of images, videos and other media types
 * @param href - URL of the preview
 * @param mimeType - MIME type of the file
 * @param filename - Name of the file
 * @param height - Height of the preview using tailwind height options (default 32)
 * @param onError - Callback for when the preview fails to load
 * @constructor
 */
export function MediaPreview({
  href,
  mimeType,
  filename,
  height = 32,
  onError,
}: {
  href: string;
  mimeType: string;
  filename?: string;
  height?: number;
  onError?: () => void;
}) {
  if (!mimeType) {
    mimeType = "application/octet-stream";
    console.warn("MediaPreview received no mimeType. It is required", filename, href);
  }

  if (mimeType.startsWith("image/")) {
    return (
      <LazyImage
        src={href}
        alt=""
        className={twMerge(`h-auto max-h-${height} w-auto max-w-${height}`)}
        previewSize={height}
        onError={onError}
      />
    );
  }
  if (mimeType.startsWith("video/")) {
    return (
      <div className={twMerge("relative", `h-${height}`)} title={filename}>
        <video className="h-full w-full" controls={false}>
          <source src={href} type={mimeType} />
        </video>

        <PlayIcon className="absolute left-1/2 top-1/2 h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-gray-600 p-2 text-white" />
      </div>
    );
  }
  return (
    <div className={twMerge("relative", `h-${height}`)} title={filename}>
      <MimeIcon mime={mimeType} className="h-full w-full" />
    </div>
  );
}
