import { ComponentProps, forwardRef, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, getIcon } from "~/lib/ui";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";

type Props = Omit<ComponentProps<typeof Button>, "children"> & {
  children?: ReactNode;
  preview: Partial<ComponentProps<typeof FullScreenFilePreview>>;
};

// Wrap <Button /> with ref and add FullScreenPDFPreviewDialog
export const PreviewButton = forwardRef<HTMLButtonElement, Props>(
  function PreviewButton(props, ref) {
    const { children, Icon, preview, ...restProps } = props;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
      <>
        <Button
          Icon={Icon ?? getIcon("preview")}
          ref={ref}
          onClick={() => setOpen(true)}
          {...restProps}
        >
          {children ?? `${t("common:see")} ${t("common:pdf")}`}
        </Button>
        <FullScreenFilePreview open={open} onClose={() => setOpen(false)} {...preview} />
      </>
    );
  }
);
