// Purpose: Abstract root level router stuff away from the application.

import { Suspense } from "react";
import { RouteObject, RouterProvider } from "react-router";
import routes from "virtual:generated-pages-react"; // Generates routes from file-structure
import { Layout } from "../ui/helpers/layout";
import { PageSpinner } from "../ui/page-spinner";

import { ToastView } from "../toast/view";
import { createBrowserRouter, initErrorReporting } from "../error-reporting";
import { ErrorRouterBoundary } from "../error-reporting/error-router-boundary";

// Error Tracing Service Setup
initErrorReporting();

// Router Setup continues
const appRoutes = routes.map((route) => routeEnhancer(route));
const router = createBrowserRouter(appRoutes);

export function NavigationRoot() {
  return <RouterProvider router={router} />;
}

// I want this to inject loaders/actions from the page components
// - But, I still haven't figured how to do that yet. Please leave in.
function routeEnhancer(route: RouteObject): RouteObject {
  const newRoute = { ...route };
  newRoute.element = (
    <Suspense fallback={<PageSpinner />}>
      <>
        <Layout>{route.element}</Layout>
        <ToastView />
      </>
    </Suspense>
  );
  newRoute.errorElement = <ErrorRouterBoundary />;

  return newRoute;
}
