import { useFormState } from "~/lib/form-state";
import Textarea from "~/lib/ui/form-elements/textarea";
import { useTranslation } from "react-i18next";
import { StreetAddressInput } from "~/lib/ui/street-address-input";
import { ZipInput } from "~/lib/ui/zip-input";
import { Contact, ContactPerson, IGetProject200Response } from "@apacta/sdk";
import { projectFormState } from "../../[id]/data";
import { CustomerSelectionWithPreview } from "~/lib/ui/selection-combobox/customer-selection-with-preview";
import { LabelInput } from "~/lib/ui";
import { CreatedModifiedLine } from "~/lib/ui/text/created-modified-line";

export default function ProjectSection({
  project,
  formState,
}: {
  project: IGetProject200Response["data"];
  formState: ReturnType<typeof useFormState<typeof projectFormState>>;
}) {
  const { t } = useTranslation();
  const { created, createdBy, modified, modifiedBy } = project;

  // Handlers for customer and contact person selection
  const handleSelectCustomer = (newCustomer?: Contact) => {
    formState.setValues({
      contactId: newCustomer?.id,
      contactPersonId: null,
    });
  };

  const handleSelectCustomerContact = (newContactPerson?: ContactPerson) => {
    formState.setValues({
      contactPersonId: newContactPerson?.id ?? null,
    });
  };

  return (
    <div className="flex-1">
      <div className="mb-4 text-gray-500">
        <CreatedModifiedLine
          created={created}
          createdBy={createdBy}
          modified={modified}
          modifiedBy={modifiedBy}
        />
      </div>
      <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
        <div className="flex flex-1 flex-col gap-6 md:basis-1/2">
          <LabelInput {...formState.registerStringInput("name")} label={t("common:name")} />
          <StreetAddressInput
            {...formState.register("streetName")}
            label={t("projects:worksite_address", { defaultValue: "Worksite address" })}
            onSelect={(item) => {
              formState.setValues({
                streetName: item.addressWithNumber,
                zipCode: item.zipCode,
                cityName: item.cityName,
                cityId: item.cityId,
              });
            }}
          />
          <div className="flex flex-row gap-4">
            <ZipInput
              placeholder={t("common:zip_code")}
              value={formState.getValue("zipCode")}
              label={t("common:zip_code", { defaultValue: "Zip code" })}
              disabled={true}
            />
            <LabelInput
              disabled
              {...formState.registerStringInput("cityName")}
              label={t("common:city_name")}
            />
          </div>
          <div>
            <Textarea
              initialValue={formState.getValue("description")}
              label={t("common:description")}
              onChange={(value) => formState.setValues({ description: value })}
              autoHeight={true}
            />
          </div>
        </div>
        <div className="flex-1 md:basis-1/2">
          <CustomerSelectionWithPreview
            selectedCustomer={project.contactId}
            selectedContactPerson={project.contactPersonId}
            onSelectCustomer={handleSelectCustomer}
            onSelectContactPerson={handleSelectCustomerContact}
          />
        </div>
      </div>
    </div>
  );
}
