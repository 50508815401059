import { LOCAL_DATE_STRING_REGEX } from "~/constants";

/**
 * LocalDate is a Date object that represents a local date in the format "YYYY-MM-DD".
 * Unlike the Date object, LocalDate does not time-shift into UTC when given a YYYY-MM-DD date string.
 *
 * @example
 * const localDate = new LocalDate("2023-10-05"); // Danish winter time time or (2023-10-05T00:00:00+1)
 * console.log(localDate.toISODateTime()); // Output: "2023-10-04T23:00:00Z"
 * // Date object creates a UTC date when given a YYYY-MM-DD date string
 * console.log(new Date("2023-10-05").toISOString()); // Output: "2023-10-05T00:00:00.000Z"
 *
 */
export class LocalDate extends Date {
  constructor(date: string) {
    if (date === undefined) {
      throw new Error("LocalDate: Received undefined. Try `LocalDate.maybe(date)` instead.");
    }
    if (typeof date !== "string") {
      throw new Error("LocalDate: Date string (YYYY-MM-DD) is required.", { cause: date });
    }

    const match = date.match(LOCAL_DATE_STRING_REGEX);
    if (!match) {
      throw new Error("LocalDate: Date string (YYYY-MM-DD) is required.", { cause: date });
    }

    // YYYY-MM-DD matched. Construct a Date object with the values
    // - Note that month is 0-indexed. So we subtract 1 from the month
    super(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
  }

  static maybe(date?: string) {
    return date ? new LocalDate(date) : undefined;
  }
}

/**
 * Converts a Date object to a local date string in the format "YYYY-MM-DD"
 * So given a "2020-01-01T00:00:00+1" will not result in a 2019-12-31 date when converted to ISO
 *
 * Note. Important that this does not silently create current timestamp
 *
 * @param date
 * @returns Local date in format "YYYY-MM-DD"
 */

// Function overrides that make sure the return type is correct
export function toLocalDateString(date: Date): string;
export function toLocalDateString(date: undefined): undefined;
export function toLocalDateString(date: Date | undefined): string | undefined;

export function toLocalDateString(date: Date | undefined): string | undefined {
  if (!date) return; // Undefined should not be converted to a date
  let d = date;
  const offset = d.getTimezoneOffset();
  d = new Date(d.getTime() - offset * 60 * 1000);
  return d.toISOString().split("T")[0];
}
