import { KpiChardHoursIndividualEntry, UsersGetSpecified200ResponseDataInner } from "@apacta/sdk";
import { useAPI } from "~/lib/api";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { getExpandedRowModel } from "@tanstack/react-table";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { useLocale } from "~/lib/utils/date";
import { FilterGroupBoolean } from "~/lib/ui/filters/filter-group-boolean";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { useState } from "react";
import { FullScreenFilePreview } from "~/lib/ui/media/full-screen-file-preview";
import { getIcon } from "~/lib/ui";
import { useEmployeesParams } from "../_cmp/use-users-params";
import { linkToFormNew } from "~/lib/utils";
import { capitalize } from "~/lib/utils/string";
import { useNavigate } from "react-router";
import { FormApprovalCell } from "~/lib/ui/table/cells/form-approval-cell";
import { ProjectCell } from "~/lib/ui/table/cells/project-cell";
import { EmployeeCell } from "~/lib/ui/table/cells/employee-cell";
import { DateCell } from "~/lib/ui/table/cells/date-cell";
import { Badge } from "~/lib/ui/badge";
import { twMerge } from "tailwind-merge";

export default function EmployeesSpecifiedTab() {
  const api = useAPI();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formatHours } = useLocale();
  const [previewFormURL, setPreviewFormURL] = useState<string | undefined>();
  const [searchParams, setSearchParams] = useTypedSearchParams<{
    isApproved?: boolean;
    isInvoiced?: boolean;
  }>();
  const employeesParams = useEmployeesParams();

  const columns = useDataColumns<UsersGetSpecified200ResponseDataInner>((columnHelper) => [
    columnHelper.accessor("formDate", {
      header: t("common:date"),
      id: "formDate",
      enableSorting: true,
      meta: {
        href: (form) => linkToFormNew(form.id),
      },
      cell: ({ row }) => (
        <DateCell
          date={row.original.formDate}
          className={twMerge(row.original.deleted && "line-through opacity-60")}
        />
      ),
    }),
    columnHelper.accessor("totalHours", {
      header: t("common:quantity"),
      id: "count",
      cell: ({ row }) => (
        <div className={twMerge("max-w-12", row.original.deleted && "line-through opacity-60")}>
          {formatHours(row.original.totalHours ?? 0)}
        </div>
      ),
      meta: {
        className: "text-left",
      },
    }),
    columnHelper.accessor("totalHours", {
      id: "unit",
      header: t("common:unit"),
      cell: ({ row }) => (
        <div className={twMerge("max-w-12", row.original.deleted && "line-through opacity-60")}>
          {capitalize(t("common:hourly"))}
        </div>
      ),
      meta: {
        className: "text-left",
      },
    }),
    columnHelper.accessor("projectName", {
      header: t("common:project", { count: 1 }),
      enableSorting: true,
      id: "projectName",
      cell: ({ row }) => (
        <ProjectCell
          projectId={row.original.projectId}
          projectName={row.original.projectName}
          className={twMerge(row.original.deleted && "line-through opacity-60")}
        />
      ),
    }),
    columnHelper.accessor("user.fullName", {
      header: t("common:employee", { count: 1 }),
      enableSorting: true,
      id: "name",
      cell: ({ row }) => (
        <EmployeeCell
          user={row.original.user!}
          className={twMerge(row.original.deleted && "line-through opacity-60")}
        />
      ),
    }),
    columnHelper.accessor("workDescription", {
      header: t("common:work_description"),
      id: "workDescription",
      cell: ({ row }) => (
        <div className={twMerge(row.original.deleted && "line-through opacity-60")}>
          {row.original.workDescription as string}
        </div>
      ),
    }),
    columnHelper.accessor("approved", {
      header: t("common:approved"),
      cell: ({ row }) => (
        <FormApprovalCell
          projectId={row.original.projectId}
          formId={row.original.id}
          value={!!row.original.approved}
          disabled={row.original.deleted}
        />
      ),
      meta: {
        className: "text-center",
      },
    }),
    columnHelper.display({
      id: "status",
      header: t("common:status"),
      cell: ({ row }) => (
        <div className="grid gap-1 text-nowrap">
          {row.original.invoiced ? (
            <Badge size="sm" variant="green">
              {t("common:invoiced")}
            </Badge>
          ) : null}
          {!row.original.invoiced ? (
            <Badge size="sm" variant="gray">
              {t("common:not_invoiced")}
            </Badge>
          ) : null}
          {row.original.deleted && (
            <Badge size="sm" variant="red">
              {t("common:deleted")}
            </Badge>
          )}
        </div>
      ),
    }),
    columnHelper.display({
      id: "actions",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <ActionButtons
          size="small"
          actions={[
            {
              Icon: getIcon("preview"),
              label: t("common:preview"),
              onClick: () => setPreviewFormURL(row.original.preview),
            },
            {
              label: t("common:edit"),
              Icon: getIcon("edit"),
              onClick: () => {
                navigate(linkToFormNew(row.original.id));
              },
            },
            {
              Icon: row.original.deleted ? getIcon("reload") : getIcon("delete"),
              label: row.original.deleted ? t("common:undelete") : t("forms:modal.delete.title"),

              disabled: row.original.deleted,
              confirm: row.original.deleted
                ? undefined
                : {
                    entity: "form",
                    action: "delete",
                  },
              onClick: () =>
                row.original.deleted
                  ? handleRestoreForm(row.original.id, row.original.projectId)
                  : handleDeleteForm(row.original.id),
            },
          ]}
        />
      ),
    }),
  ]);
  const tableState = useDataTableState({
    expanded: undefined,
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
  });

  const dataQ = useQuery({
    queryKey: [
      "employees.specified",
      searchParams,
      employeesParams,
      tableState.state.pagination,
      tableState.state.sorting,
    ],
    queryFn: () =>
      api.usersGetSpecified({
        approved: searchParams.isApproved,
        invoiced: searchParams.isInvoiced,
        sort: tableState.sortBy,
        direction: tableState.sortingDirection,
        ...employeesParams,
      }),
  });

  function refresh() {
    dataQ.refetch();
  }

  async function handleDeleteForm(id: string) {
    await api.deleteForm({ formId: id });
    refresh();
  }

  async function handleRestoreForm(formId: string, projectId: string) {
    await api.undeleteForm({ formId, projectId });
    refresh();
  }

  const table = useDataTable(
    {
      columns,
      tableState,
      data: dataQ.data?.data,
      isLoading: dataQ.isLoading,
      getRowId: (row) => row.id,
    },
    {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand(row) {
        if (!row.original.entries || row.original.entries.length < 1) return false;
        return row.original.entries.some((item: KpiChardHoursIndividualEntry) => item.count > 0);
      },
    }
  );

  return (
    <div className="flex flex-col gap-2">
      <h2 className="mb-2"> {t("users:specified_overview", "Specified overview")}</h2>
      <DataTable
        table={table}
        renderFilters={() => (
          <>
            <FilterGroupBoolean
              name={t("users:filters.approved", "Approved")}
              value={searchParams.isApproved}
              onClear={() => setSearchParams("isApproved", undefined)}
              onConfirmSelection={(selection) => {
                setSearchParams("isApproved", selection);
              }}
            />
            <FilterGroupBoolean
              name={t("projects:filters.invoiced", "Invoiced")}
              value={searchParams.isInvoiced}
              onClear={() => setSearchParams("isInvoiced", undefined)}
              onConfirmSelection={(selection) => {
                setSearchParams("isInvoiced", selection);
              }}
            />
          </>
        )}
        renderExpandedRow={({ row }) => {
          return (
            <table className="border">
              <tbody>
                {row.original.entries?.map((entry: KpiChardHoursIndividualEntry) => {
                  return entry.count ? (
                    <tr key={entry.entityId} className="bg-gray-50">
                      <td className="w-[9.2rem] min-w-[9.2rem] ">&nbsp;</td>
                      <td className="w-28 min-w-28">
                        <EntryValue entry={entry} />
                      </td>
                      <td>{entry.label}</td>
                      <td></td>
                      <td className="text-right"></td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          );
        }}
      />
      <FullScreenFilePreview
        open={!!previewFormURL}
        fileUrl={previewFormURL}
        onClose={() => setPreviewFormURL(undefined)}
      />
    </div>
  );
}

function EntryValue({ entry }: { entry: KpiChardHoursIndividualEntry }) {
  const { formatHours } = useLocale();
  const { t } = useTranslation();
  switch (entry.type) {
    case "driving":
      return `${entry.count} km`;
    case "hourly":
      return formatHours(entry.count);
    case "daily":
      return `${entry.count} ${t("common:day", { count: entry.count }).toLowerCase()}`;
  }
}
