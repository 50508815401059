import { PageLayout } from "~/lib/ui/page-layout";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { useNavigate } from "react-router";
import OffersKpiCards from "~/pages/projects/[id]/_cmp/offers-kpi-cards";
import { Button } from "~/lib/ui";
import { OfferTable } from "./_cmp/offer-table";

export const CACHE_OFFERS = "offers";

export default function OffersPage() {
  const api = useAPI();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleCreateOffer = (): void => {
    api
      .createOffer({ editOfferRequest: { title: t("offers:new_offer"), isQuote: true } })
      .then((v) => {
        if (v.data?.id) {
          navigate(v.data.id);
        }
      });
  };

  return (
    <PageLayout
      title={t("navigation:offers.title")}
      renderActions={() => (
        <Button
          Icon={PlusIcon}
          variant="tertiary"
          className="print:hidden"
          onClick={handleCreateOffer}
        >
          {t("common:create", {
            defaultValue: "Create {{entity}}",
            replace: { entity: t("common:offer_quote", { count: 1 }).toLocaleLowerCase() },
          })}
        </Button>
      )}
    >
      <OffersKpiCards />
      <OfferTable parentType="page" />
    </PageLayout>
  );
}
