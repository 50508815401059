import { ControlPanelApiPaymentTerm } from "@apacta/sdk";
import { useTranslation } from "react-i18next";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useToastOnError } from "~/lib/utils/hooks";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";
import { LabelInput } from "../form-elements";
import { useState } from "react";
import { useLocale } from "~/lib/utils/date";

export function PaymentConditionSelection({
  onChange,
  initialConditionId,
  hideLabel = false,
  showDueDateForInvoiceId,
}: {
  onChange: (condition: ControlPanelApiPaymentTerm | null) => void;
  initialConditionId?: string;
  hideLabel?: boolean;
  showDueDateForInvoiceId?: string;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const locale = useLocale();
  const [internalConditionId, setInternalConditionId] = useState<string | undefined>(
    initialConditionId
  );

  const { data, error } = useSuspenseQuery({
    queryKey: ["conditions"],
    queryFn: () => api.getPaymentTerms(),
  });

  useToastOnError(error);

  const conditions = data?.data ?? [];

  const handleSelect = (conditionId?: string) => {
    setInternalConditionId(conditionId);
    if (!conditionId) {
      onChange?.(null);
      return;
    }

    const selectedCondition = conditions.find((c) => c.id === conditionId);
    if (selectedCondition) {
      onChange?.(selectedCondition);
    }
  };

  const dueDateEnabled = !!showDueDateForInvoiceId && !!internalConditionId;

  const dueDateQ = useQuery({
    queryKey: ["dueDate", showDueDateForInvoiceId, internalConditionId],
    queryFn: async () => {
      const res = await api.calculateInvoiceDueDate({
        calculateInvoiceDueDateRequest: {
          paymentTermId: internalConditionId!,
          invoiceId: showDueDateForInvoiceId!, // never called if not defined
        },
      });
      return res;
    },
    enabled: dueDateEnabled,
  });

  const dueDate = dueDateQ.data?.data?.paymentDueDate;

  return (
    <>
      <SelectionCombobox
        required
        label={hideLabel ? "" : t("customers:modal.create_customer.input.conditions")}
        defaultValue={initialConditionId}
        onSelectValue={handleSelect}
        data={conditions}
        valueFn={(e) => e.id ?? ""}
        labelFn={(e) => e.name ?? ""}
      />
      {dueDateEnabled && (
        <div className="mt-4">
          <LabelInput
            label={t("common:due_date")}
            value={
              dueDate
                ? locale.format(dueDate, {
                    excludeTime: true,
                  })
                : `${t("common:loading")}...`
            }
            readOnly
          />
        </div>
      )}
    </>
  );
}
