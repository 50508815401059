import { OrderComplaintsDetailQuery } from "~/lib/gql";
import { notNullOrUndefinedFilter } from "~/lib/utils/filters";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { useTranslation } from "react-i18next";
import { CurrencyCell } from "~/lib/ui/table/cells/currency-cell";
import { NumberCell } from "~/lib/ui/table/cells/number-cell";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { randomUUID } from "~/lib/uuid/random-uuid";

type UnknownProductsTableData =
  OrderComplaintsDetailQuery["orderComplaint"]["unknownProductErrors"];

export default function UnknownProductsTable({ lines }: { lines: UnknownProductsTableData }) {
  const { t } = useTranslation();

  const filteredLines = lines?.filter(notNullOrUndefinedFilter) ?? [];
  const tableState = useDataTableState();

  const columns = useDataColumns<(typeof filteredLines)[number]>((columnHelper) => [
    columnHelper.accessor("productNumber", {
      header: `${t("common:product", { count: 1 })} ${t("common:number_abbr").toLowerCase()}`,
      cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor("productName", {
      header: t("common:product", { count: 1 }),
      cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor("quantity", {
      header: t("common:quantity"),
      meta: {
        className: "text-right",
      },
      cell: ({ getValue }) => <NumberCell value={getValue()} />,
    }),
    columnHelper.accessor("paidPrice", {
      header: t("procurement:order_complaints.table.paid_price"),
      meta: {
        className: "text-right",
      },
      cell: ({ getValue }) => <CurrencyCell value={getValue()} />,
    }),
  ]);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: filteredLines,
      isLoading: false,
      getRowId: (row) => row.id ?? randomUUID(), // id is optional for whatever reason from backend
    },
    { enableGlobalFilter: false }
  );

  return (
    <div>
      <div>
        <div className="font-medium">
          {t("procurement:order_complaints.error.product_is_unknown", {
            count: filteredLines.length,
          })}
        </div>
        <div className="text-sm">
          {t("procurement:order_complaints.error.product_is_unknown.description")}
        </div>
      </div>
      <DataTable table={table} hidePagination />
    </div>
  );
}
