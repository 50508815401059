import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { getIcon, Icon, Label } from "~/lib/ui";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { DialogBody } from "~/lib/ui/dialog/dialog-body";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { useFormState } from "~/lib/form-state";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useGraphQL } from "~/lib/gql";
import z from "zod";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { CACHE_PROCUREMENT, ProcurementPageSearchParams } from "~/pages/procurement";
import { CreateProcurementAnalysisInput } from "~/lib/gql/generated/graphql";
import { useTranslation } from "react-i18next";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { toLocalDateString } from "~/lib/utils/date";
import CompaniesVendorSelection from "~/lib/ui/vendor/companies-vendor-selection";
import { useMemo } from "react";

export default function CreatePriceAnalysisDialog({
  dateFrom,
  dateTo,
  onClose,
}: {
  dateFrom?: Date;
  dateTo?: Date;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [searchParams] = useTypedSearchParams<ProcurementPageSearchParams>();

  const formState = useFormState({
    schema: {
      dateStart: z.string().date().min(10), // YYYY-MM-DD - 10 characters
      dateEnd: z.string().date().min(10), // YYYY-MM-DD - 10 characters
      companiesVendorIds: z.array(z.string()).nullish(), // Array of vendor ids
    },
    initialValues: {
      dateStart: dateFrom ? toLocalDateString(dateFrom) : "",
      dateEnd: dateTo ? toLocalDateString(dateTo) : "",
      companiesVendorIds: searchParams.companiesVendorIds,
    },
  });

  const sdk = useGraphQL();
  const queryClient = useQueryClient();

  const createPriceAnalysisMutation = useMutation({
    mutationFn: (args: CreateProcurementAnalysisInput) =>
      sdk.procurementAnalysisCreate({
        input: args,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CACHE_PROCUREMENT] });
      onClose();
    },
  });

  const handleCreatePriceAnalysis = async () => {
    await createPriceAnalysisMutation.mutateAsync({
      dateInterval: {
        from: formState.getValue("dateStart"),
        to: formState.getValue("dateEnd"),
      },
      companiesVendorIds: formState.getValue("companiesVendorIds"),
    });
  };

  const handleVendorSelect = (selectedIds: Array<string>) => {
    formState.setValues({ companiesVendorIds: selectedIds.length > 0 ? selectedIds : undefined });
  };

  const handleDateRangeChange = ({ from, to }: DateRange) => {
    formState.setValues({
      dateStart: from ? toLocalDateString(from) : "",
      dateEnd: to ? toLocalDateString(to) : "",
    });
  };

  const selectedDateRange: DateRange = useMemo(() => {
    return {
      to: formState.getValue("dateEnd") ? new Date(formState.getValue("dateEnd")) : undefined,
      from: formState.getValue("dateStart") ? new Date(formState.getValue("dateStart")) : undefined,
    };
  }, [formState]);

  return (
    <>
      <DialogHeader
        title={t("common:create", {
          entity: t("common:price_analysis", { count: 1 }).toLowerCase(),
        })}
        Icon={getIcon("add")}
      />
      <DialogBody>
        <div className="flex flex-col gap-6">
          <div>
            <Label required>{t("common:date_range")}</Label>
            <DateRangePopover
              compact={false}
              dateFrom={
                !!formState.getValue("dateStart")
                  ? new Date(formState.getValue("dateStart"))
                  : undefined
              }
              dateTo={
                !!formState.getValue("dateEnd")
                  ? new Date(formState.getValue("dateEnd"))
                  : undefined
              }
              onChange={handleDateRangeChange}
            />
          </div>

          <div>
            <CompaniesVendorSelection
              defaultSelected={formState.getValue("companiesVendorIds") ?? undefined}
              dateRange={{ from: selectedDateRange.from, to: selectedDateRange.to }}
              onSelect={handleVendorSelect}
            />
          </div>

          <div className="flex flex-col gap-2 border bg-gray-100 p-2">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-yellow-500 p-1.5 text-white">
                <Icon name="warningTriangle" className="h-6 w-6" />
              </div>
              <div className="text-sm">{t("procurement:create_dialog.vendor_warning")}</div>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter
        primary={{
          label: t("common:create", {
            entity: t("common:price_analysis", { count: 1 }).toLowerCase(),
          }),
          onClick: handleCreatePriceAnalysis,
          disabled: !formState.isValid && !createPriceAnalysisMutation.isPending,
          loading: createPriceAnalysisMutation.isPending,
        }}
        onClose={onClose}
      />
    </>
  );
}
