import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import {
  Pagination_Direction,
  useGraphQL,
  Vendor_Product_Price_File_Line_List_Sort_Fields,
} from "~/lib/gql";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useToastOnError } from "~/lib/utils/hooks";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import LazyImage from "~/lib/ui/lazy-image";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { formatCurrency, formatDecimals } from "~/lib/utils/number";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const CACHE_PRICE_FILE_LINES = "price-file-lines";
export default function PriceFileAnalysisTable() {
  const { priceFileId } = useParams<{ priceFileId: string }>();
  const { t } = useTranslation();
  const sdk = useGraphQL();

  const tableState = useDataTableState({
    sorting: [
      {
        desc: !!Pagination_Direction.Desc,
        id: "Consequence",
      },
    ],
  });

  const priceFileQ = useQuery({
    queryKey: [CACHE_PRICE_FILE_LINES, priceFileId],
    queryFn: async () =>
      sdk.priceFileDetailLines({
        params: {
          vendorProductPriceFileId: priceFileId as string,
          sortBy:
            Vendor_Product_Price_File_Line_List_Sort_Fields[
              tableState.sortBy as keyof typeof Vendor_Product_Price_File_Line_List_Sort_Fields
            ],
          direction:
            tableState.sortingDirection === "desc"
              ? Pagination_Direction.Desc
              : Pagination_Direction.Asc,
          q: tableState.state.search,
        },
      }),
  });

  useToastOnError(priceFileQ.error);

  const priceFileData = priceFileQ.data?.vendorProductPriceFileLines?.edges ?? []; //TODO LAH USE edge/node gql pagination

  const columns = useDataColumns<(typeof priceFileData)[number]>((columnHelper) => [
    columnHelper.accessor("node.vendorProduct.name", {
      header: t("common:product", { count: 1 }),
      cell: ({ row }) => (
        <div className="flex items-center gap-4">
          {row.original.node.vendorProduct.imageUrls?.thumbnail ? (
            <LazyImage
              src={row.original.node.vendorProduct.imageUrls?.thumbnail}
              alt={row.original.node.vendorProduct.name}
              className="h-auto w-16 shrink-0"
            />
          ) : (
            <img
              src="/image_not_found.jpg"
              alt={row.original.node.vendorProduct.name}
              className="h-auto w-16"
            />
          )}
          <OptionalLink to={`/products/vendor-products/${row.original.node.vendorProduct.id}`}>
            {row.original.node.vendorProduct.name}
          </OptionalLink>
        </div>
      ),
    }),
    columnHelper.accessor("node.vendorProduct.productNumber", {
      header: t("common:product_number"),
      cell: ({ row }) => (
        <OptionalLink to={`/products/vendor-products/${row.original.node.vendorProduct.id}`}>
          {row.original.node.vendorProduct.productNumber}
        </OptionalLink>
      ),
    }),
    columnHelper.accessor("node.quantityBought", {
      id: "QuantityBought",
      header: t("common:quantity"),
      cell: ({ row }) => <span>{formatDecimals(row.original.node.quantityBought, 2)}</span>,
    }),
    columnHelper.accessor("node.previousPrice", {
      header: `${t("common:previous")} ${t("common:file", { count: 1 }).toLowerCase()}`,
      cell: ({ row }) => <span>{formatCurrency(row.original.node.previousPrice)}</span>,
    }),
    columnHelper.accessor("node.currentPrice", {
      header: `${t("common:current")} ${t("common:file", { count: 1 }).toLowerCase()}`,
      cell: ({ row }) => <span>{formatCurrency(row.original.node.currentPrice)}</span>,
    }),
    columnHelper.display({
      id: "PriceChange",
      header: t("common:difference"),
      cell: ({ row }) => <span>{formatDecimals(row.original.node.priceChangePercentage)}%</span>,
    }),
    columnHelper.display({
      id: "Consequence",
      header: t("common:consequence"),
      cell: ({ row }) => {
        const oldPerItem = row.original.node.previousPrice;
        const newPerItem = row.original.node.currentPrice;
        const consequencePerItem = newPerItem - oldPerItem;

        return (
          <div className="flex flex-col">
            <span
              className={twMerge(
                "text-lg font-medium",
                row.original.node.consequence > 0 ? "text-red-500" : "text-green-600"
              )}
            >
              {formatCurrency(row.original.node.consequence)}
            </span>
            <span className="text-xs">{`${formatCurrency(consequencePerItem)} /${t("common:each_abbr")}`}</span>
          </div>
        );
      },
    }),
  ]);

  const table = useDataTable(
    {
      columns,
      tableState,
      data: priceFileData,
      isLoading: priceFileQ.isFetching,
      getRowId: (row) => row.node.id,
    },
    { enableGlobalFilter: true }
  );

  return <DataTable table={table} />;
}
