import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { FeedCard } from "./feed-card";
import { useAPI } from "~/lib/api";
import { Fragment, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useToastOnError } from "~/lib/utils/hooks";
import { toLocalDateString, useLocale } from "~/lib/utils/date";
import { useTranslation } from "react-i18next";
import { Spinner } from "~/lib/ui/spinner";
import { DateRangePopover } from "~/lib/ui/calendar/date-range-popover";
import { DateRange } from "~/lib/utils/date/date-utils.types";
import { datesInRange } from "~/lib/utils/date/date-utils";

const getDefaultDateFrom = () => {
  const d = new Date();
  d.setDate(d.getDate() - 7);
  return d;
};

const getDefaultDateTo = () => {
  return new Date();
};

export default function FeedOverview() {
  const { t } = useTranslation();
  const { format } = useLocale();
  const api = useAPI();

  const [dateFrom, setDateFrom] = useState<Date>(getDefaultDateFrom());

  const [dateTo, setDateTo] = useState<Date>(getDefaultDateTo());

  const { data, isFetching, error } = useQuery({
    queryKey: ["frontpageFeedCards", dateFrom, dateTo],
    queryFn: () =>
      api
        .iGetFeed({ dateFrom: toLocalDateString(dateFrom), dateTo: toLocalDateString(dateTo) })
        .then((res) => {
          return res;
        }),
    refetchInterval: 60 * 1000 * 60, // 60 minutes
  });

  useToastOnError(error);

  const feedData = data?.data ?? [];
  const dates = datesInRange(dateFrom, dateTo, true).sort((a, b) => b.getTime() - a.getTime());

  const getCardsByDate = (d: Date) => {
    return feedData
      .filter((card) => {
        if (!card.date) return false; // skip if no date is available
        return format(card.date, { shortDate: true }) === format(d, { shortDate: true });
      })
      .sort((a, b) => {
        if (!a.form?.created || !b.form?.created) return 0;
        return new Date(b.form.created).getTime() - new Date(a.form.created).getTime();
      });
  };

  const handleDateRangeChange = (range: DateRange) => {
    setDateFrom(range.from ?? getDefaultDateFrom());
    setDateTo(range.to ?? getDefaultDateTo());
  };

  const isTodaysDate = (d: Date): boolean => {
    return format(d, { shortDate: true }) === format(new Date(), { shortDate: true });
  };

  const isYesterdaysDate = (d: Date): boolean => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return format(d, { shortDate: true }) === format(yesterday, { shortDate: true });
  };

  const getDividerLabel = (d: Date) => {
    if (isTodaysDate(d)) return t("ui:date_range_picker.today");
    if (isYesterdaysDate(d)) return t("ui:date_range_picker.yesterday");

    return format(d, { shortDate: true });
  };

  const handleDateRangeReset = () => {
    setDateFrom(getDefaultDateFrom());
    setDateTo(getDefaultDateTo());
  };

  return (
    <div>
      <DateRangePopover
        dateFrom={dateFrom}
        dateTo={dateTo}
        onChange={handleDateRangeChange}
        resetLabel={t("common:last_x_days", { x: 7 })}
        onReset={handleDateRangeReset}
      />
      <div className="flex flex-col gap-8">
        {dates.map((d) => {
          const cardsForDay = getCardsByDate(d);

          return (
            <Fragment key={d.getTime()}>
              <HorizontalDivider lineClassName="border-gray-400">
                <span className="whitespace-nowrap rounded-lg bg-primary px-6 py-1 text-lg text-white">
                  {getDividerLabel(d)}
                </span>
              </HorizontalDivider>
              <div className="flex w-full flex-wrap">
                {isFetching ? (
                  <div className="flex h-72 w-full flex-col items-center justify-center gap-2">
                    <Spinner />
                    <p>{t("common:loading")}</p>
                  </div>
                ) : cardsForDay.length ? (
                  <>
                    {cardsForDay.map((card) => (
                      <FeedCard key={card.id} data={card} />
                    ))}
                  </>
                ) : (
                  <div className="flex w-full justify-center text-gray-400">
                    <div>{t("common:no_data")}</div>
                  </div>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
