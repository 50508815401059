import { Invoice, InvoiceLine } from "@apacta/sdk";
import { z } from "zod";
import { DEFAULT_VAT_IF_SETTING_IS_MISSING } from "~/constants";
import { useFormState } from "~/lib/form-state";

export const invoiceSchema = {
  customerId: z.string().optional(),
  contactPersonId: z.string().optional(),
  issueDate: z.date().optional(),
  reference: z.string().optional(),
  message: z.string().optional(),
  lines: z.array(z.custom<InvoiceLine>((line) => line)),
  paymentTermId: z.string().optional(),
  vatPercent: z.number(),
};

export type InvoiceFormState = ReturnType<typeof useInvoiceFormState>;
// Note: Getting session to work with tests atm is too tricky.
// - So we add vat as a param instead
export function useInvoiceFormState(
  invoice: Invoice,
  companyVatPercent: number = DEFAULT_VAT_IF_SETTING_IS_MISSING
) {
  const getInitialValues = () => {
    return {
      customerId: invoice.contactId,
      contactPersonId: invoice.contactPersonId,
      issueDate: invoice.issuedDate,
      reference: invoice.reference,
      message: invoice.message,
      lines: invoice.invoiceLines ?? [],
      paymentTermId: invoice.paymentTermId,
      vatPercent: invoice.vatPercent ?? companyVatPercent,
    };
  };

  const formState = useFormState({
    schema: invoiceSchema,
    initialValues: getInitialValues(),
  });

  return formState;
}
