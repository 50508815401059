import { JSX } from "react";
import { appendCustomTheme } from "./editor/theme";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { EditorToolbar } from "./editor/editor-toolbar";
import { forwardRef, ReactNode, useId, useImperativeHandle, useRef, useState } from "react";
import { EditorBody, RichTextEditorBodyRef } from "./editor/editor-body";
import { EditorThemeClasses, NodeKey, ParagraphNode } from "lexical";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import TemplatePlugin from "~/lib/ui/rich-text-editor/editor/plugins/template-plugin";
import { DatasetParagraphNode } from "~/lib/ui/rich-text-editor/editor/nodes/DatasetParagraphNode";
import { DatasetHeadingNode } from "~/lib/ui/rich-text-editor/editor/nodes/DatasetHeadingNode";

export type RichTextEditorInsertAction = {
  type: "variable" | "template";
  content: string;
  inline?: boolean;
  clear?: boolean;
  key?: NodeKey;
};

export type ToolbarAction = {
  render: () => ReactNode;
  onClick: () => Promise<RichTextEditorInsertAction> | void;
};

type RichTextEditorProps = {
  name?: string;
  label?: string;
  initialData?: string;
  limitHeight?: boolean;
  variables?: { [key: string]: () => JSX.Element };
  toolbarActions?: Array<ToolbarAction>;
  autofocus?: boolean;
  onChange?: (text: string, html: string, valid: boolean) => void;
  required?: boolean;
  preview?: boolean;
  characterLimit?: number;
  disableRichText?: boolean;
  editorTheme?: Pick<EditorThemeClasses, "heading" | "paragraph" | "list">;
};
export type RichTextEditorRef = {
  setEditorMarkdown: (data: string, replace?: boolean) => void;
  getEditorMarkdown: () => Promise<string>;
  getEditorHTML: () => Promise<string>;
};

type EditorErrorMessage = {
  message: string;
};

type EditorValidState = {
  valid: boolean;
  requiredError?: string;
  characterLimitError?: string;
  containsTagsError?: string;
};

/**
 * RichTextEditor - A rich text editor component
 * @param {Ref<RichTextEditorRef>} ref - ref to the editor - use this to get/set the editor content
 * @param {string} name - namespace for the editor, only needed if you have multiple editors on the same page
 * @param {string} initialData - initial data to be loaded into the editor
 * @param {boolean} limitHeight - if true, the editor will not grow in height as you type, but rather show a scrollbar
 * @param {Array<ToolbarAction>} toolbarActions - custom toolbar actions to be added to the editor
 * @param {function(string):void} onChange - callback for when the editor content changes
 * @param {boolean} required - if true, the editor will show an error if it is empty
 * @param {boolean} preview - if true, the editor will not be editable
 * @param {number} characterLimit - if set, the editor will show an error if the character limit is exceeded
 * @param {boolean} disableRichText - if true, the editor will only allow plain text
 * @param {EditorThemeClasses} editorTheme - custom texts theme for the editor
 * @returns forwardRef<RichTextEditorRef>
 */
export const RichTextEditor = forwardRef<RichTextEditorRef, RichTextEditorProps>(
  (
    {
      name = "rteditor",
      label,
      initialData,
      limitHeight = false,
      variables,
      toolbarActions,
      autofocus = false,
      onChange,
      required = false,
      preview = false,
      characterLimit,
      disableRichText = false,
      editorTheme,
    }: RichTextEditorProps,
    ref
  ) => {
    const editorBodyRef = useRef<RichTextEditorBodyRef>(null);

    const [editorValidState, setEditorValidState] = useState<EditorValidState>({ valid: true });

    const EditorTheme = appendCustomTheme(editorTheme as EditorThemeClasses);

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      setEditorMarkdown(data: string, replace = true): void {
        if (editorBodyRef.current) {
          editorBodyRef.current.setEditorMarkdown(data.trim(), replace);
        }
      },
      async getEditorMarkdown(): Promise<string> {
        if (editorBodyRef.current) {
          const markdownText = await editorBodyRef.current.getEditorMarkdown();
          return markdownText.trim();
        }
        return "";
      },
      async getEditorHTML(): Promise<string> {
        if (editorBodyRef.current) {
          const htmlText = await editorBodyRef.current.getEditorHTML();
          return htmlText.trim();
        }
        return "";
      },
    }));

    const initialConfig: InitialConfigType = {
      namespace: name,
      theme: EditorTheme,
      onError: (e: unknown) => console.log(e),
      nodes: disableRichText
        ? []
        : [
            DatasetHeadingNode,
            {
              replace: HeadingNode,
              with: (node) => {
                return new DatasetHeadingNode(node.__tag);
              },
            },
            QuoteNode,
            ListNode,
            ListItemNode,
            DatasetParagraphNode,
            {
              replace: ParagraphNode,
              with: () => {
                return new DatasetParagraphNode();
              },
            },
          ],
      editable: autofocus, // fix for autofocus issue
    };

    const id = useId();

    const hasTags = (text: string): boolean => {
      return /(<([^>]+)>)/gi.test(text);
    };

    const handleChange = (text: string, html: string, markdown: string) => {
      let valid = true;
      let requiredError, characterLimitError, containsTagsError;
      if (required) {
        if (text.length === 0) {
          valid = false;
          requiredError = t("ui:editor.error.required");
        }
      }

      if (characterLimit) {
        if (text.length > characterLimit) {
          valid = false;
          characterLimitError = t("ui:editor.error.limit_exceeded", { limit: characterLimit });
        }
      }

      if (hasTags(text)) {
        valid = false;
        containsTagsError = t("ui:editor.error.contains_tags");
      }

      onChange?.(text.trim(), html.trim(), valid);
      setEditorValidState({
        valid,
        requiredError,
        characterLimitError,
        containsTagsError,
      });
    };

    return (
      <div>
        {label && (
          <label htmlFor={id} className="mb-1 block text-left text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <div
          className={twMerge(
            preview ? "" : "rounded-lg border bg-white shadow-sm",
            editorValidState ? "" : "rounded-lg border-red-300"
          )}
        >
          <LexicalComposer initialConfig={initialConfig}>
            <div className="editor-container">
              <TemplatePlugin />
              {!preview && (
                <EditorToolbar
                  namespace={initialConfig.namespace}
                  toolbarActions={toolbarActions}
                  isRichText={!disableRichText}
                />
              )}
              <EditorBody
                id={id}
                initialData={initialData}
                variables={variables}
                ref={editorBodyRef}
                limitHeight={limitHeight}
                autofocus={autofocus}
                // Bug: This triggers on initial render. Ask Lars how we can make this input component more well behaved
                onChange={handleChange}
                preview={preview}
                characterLimit={characterLimit}
                isRichText={!disableRichText}
              />
            </div>
          </LexicalComposer>
        </div>
        {!editorValidState.valid && (
          <>
            {editorValidState.requiredError && (
              <p className="mt-2 text-left text-sm text-red-600">
                {editorValidState.requiredError}
              </p>
            )}
            {editorValidState.characterLimitError && (
              <p className="mt-2 text-left text-sm text-red-600">
                {editorValidState.characterLimitError}
              </p>
            )}
            {editorValidState.containsTagsError && (
              <p className="mt-2 text-left text-sm text-red-600">
                {editorValidState.containsTagsError}
              </p>
            )}
          </>
        )}
      </div>
    );
  }
);

RichTextEditor.displayName = "RichTextEditor";
