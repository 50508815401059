import { memo, ReactNode } from "react";
import { PageLayout } from "../ui/page-layout";
import { TabHeading } from "../ui/tabs/heading";

/**
 * Renders forms in a consistent way, regardless of it's a stand-alone page or tab.
 */
function FormViewWrapperInner(props: {
  children: ReactNode;
  mode: "project" | "stand-alone";
  title: string;
  actions?: ReactNode;
  description?: ReactNode;
  hideBackButton?: boolean;
}) {
  if (props.mode === "stand-alone") {
    return (
      <PageLayout
        title={props.title}
        renderActions={() => props.actions}
        renderDescription={() => props.description}
      >
        {props.children}
      </PageLayout>
    );
  }
  return (
    <>
      <TabHeading subtitle={props.description} actionArea={props.actions}>
        {props.title}
      </TabHeading>
      {props.children}
    </>
  );
}

export const FormViewWrapper = memo(FormViewWrapperInner);
