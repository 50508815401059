import { matchDates, Week } from "~/lib/utils/date/date-utils";
import {
  FormEntryCard,
  WeekSectionProjectForm,
} from "~/pages/_cmp/weekly-registration-table/form-entry-card";
import { twMerge } from "tailwind-merge";
import { WeeklyProject } from "~/pages/employees/index/weekly";
import Switch from "~/lib/ui/switch";
import Tooltip from "~/lib/ui/tooltip";
import { useTranslation } from "react-i18next";
import { AuthorizedLink } from "~/lib/utils/routing/authorized-link";
import { linkToProject } from "~/lib/utils";
import { useState } from "react";

type WeekSectionProjectProps = {
  week: Week;
  project: WeeklyProject;
  onFormClick?: (form: WeekSectionProjectForm) => void;
  canApproveForms?: boolean;
  onToggle?: (projectId: string, state: boolean, forms: Array<string>) => void;
};

export function WeekSectionProject({
  week,
  project,
  onFormClick,
  canApproveForms = false,
  onToggle,
}: WeekSectionProjectProps) {
  const { t } = useTranslation();
  const forms = project.forms.filter((form) => !!form.date);
  // Handles toggling all forms in the project
  const handleProjectToggle = (state: boolean) => {
    // Update local project state
    setFormStates((prevStates) =>
      forms.map((form, index) => (!form.disabled ? state : prevStates[index]))
    );
    const enabledFormIds = forms.filter((form) => !form.disabled).map((form) => form.id);
    // Update all forms at once
    onToggle?.(project.id, state, enabledFormIds);
  };

  // Track the approval status of each form
  const [formStates, setFormStates] = useState(() => forms.map((form) => !!form.approved));
  // Calculate if all forms are approved
  const allFormsApproved = formStates.every((state) => state);
  const handleFormToggle = (formId: string, state: boolean) => {
    // Update the formStates based on the formId
    setFormStates((prevStates) =>
      forms.map((form, index) => (form.id === formId ? state : prevStates[index]))
    );
    onToggle?.(project.id, state, [formId]);
  };
  return (
    <tr className="divide-x hover:bg-gray-50 print:divide-x-2">
      <td className="min-w-72 px-3 py-1.5 align-top text-sm print:min-w-0 print:max-w-20">
        <div className="flex justify-between gap-4">
          <div className="line-clamp-1 font-semibold">
            <AuthorizedLink to={linkToProject(project.id)} openInNewTab={true}>
              {project.name}
            </AuthorizedLink>
          </div>
          {canApproveForms && !!onToggle && (
            <Tooltip
              delay={300}
              trigger={
                <div>
                  <Switch
                    controlled
                    size="small"
                    onCheckedChange={handleProjectToggle}
                    checked={allFormsApproved}
                  />
                </div>
              }
            >
              {t("forms:approve_all_on_project")}
            </Tooltip>
          )}
        </div>
        <div className="text-nowrap text-sm text-shade-400">
          {!!project.streetName && <div>{project.streetName}</div>}
          {!!project.cityName && !!project.zipCode && (
            <div>{`${project.zipCode} ${project.cityName}`}</div>
          )}
        </div>
      </td>
      {week.days.map((day) => (
        <td
          key={day.date.getTime()}
          className={twMerge(
            "w-24 min-w-24 items-center gap-2 align-top print:w-auto",
            day.isHoliday && "bg-yellow-50",
            day.isWeekend && "bg-shade-200/50"
          )}
        >
          <div className="flex h-full w-full flex-col items-center gap-1 divide-y">
            {forms.map(
              (form, index) =>
                matchDates(form.date!, day.date, { ignoreTimestamp: true }) && (
                  <FormEntryCard
                    key={form.id}
                    form={form}
                    onClick={() => onFormClick?.(form)}
                    showApprovedState={canApproveForms}
                    forceChecked={formStates[index]}
                    onToggle={({ state, formId }) => {
                      handleFormToggle(formId, state);
                    }}
                  />
                )
            )}
          </div>
        </td>
      ))}
    </tr>
  );
}
