import { Outlet, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAPI } from "~/lib/api";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PageLayout } from "~/lib/ui/page-layout";
import { Suspense, useState } from "react";
import { PageSpinner } from "~/lib/ui/page-spinner";
import { DeleteUserRequest, EditUserOperationRequest } from "@apacta/sdk";
import { useToasts } from "~/lib/toast/use-toasts";
import { ChangeUserPasswordModal } from "~/pages/employees/_cmp/change-user-password-modal";
import PageTabs from "~/lib/ui/tabs/page-tabs";
import { useToastOnError } from "~/lib/utils/hooks";
import { Dialog, getIcon } from "~/lib/ui";
import { ActionButtons } from "~/lib/ui/action-buttons";
import { CACHE_EMPLOYEES } from "~/pages/employees/index";
import { Badge } from "~/lib/ui/badge";
import { useMe } from "~/lib/auth/use-me";
import { linkToEmployees } from "~/lib/utils";

export default function UserPage() {
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const { id: userId } = useParams();
  const { show: addToast } = useToasts();
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["user", userId],
    queryFn: () => api.viewUser({ userId: userId as string }),
  });

  const [passwordChangeOpen, setPasswordChangeOpen] = useState<boolean>(false);

  useToastOnError(error);

  const user = data?.data;
  const userDelete = useMutation({
    mutationFn: (args: DeleteUserRequest) => api.deleteUser(args),
    onSuccess: async () => {
      addToast({
        title: t("users:toast.delete.title"),
        description: t("users:toast.delete.description"),
        Icon: getIcon("success"),
        timeout: 3000,
      });
      await queryClient.invalidateQueries({
        queryKey: [CACHE_EMPLOYEES],
      });
    },
  });

  const resendWelcomeSms = useMutation({
    mutationFn: () =>
      api.resendWelcomeSms({
        userId: userId as string,
      }),
    onSuccess: () => {
      addToast({
        title: t("users:toast.resend_sms.success.title"), // Success
        description: t("users:toast.resend_sms.success.description"), // 'Der er sendt en SMS til brugeren'
        Icon: getIcon("success"),
        timeout: 5000,
      });
    },
    onError: () => {
      addToast({
        title: t("users:toast.resend_sms.fail.title"), // Failure
        description: t("users:toast.resend_sms.fail.description"), // 'Der skete en fejl ved afsendelse af SMS'
        Icon: getIcon("errorCircle"),
        timeout: 5000,
      });
    },
  });
  const handleUserActivation = async () => {
    if (!user) return;
    userActivation
      .mutateAsync({
        userId: user.id as string,
        editUserRequest: {
          ...user,
          isActive: !user.isActive,
        },
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ["user", userId],
        });
      });
  };

  const userActivation = useMutation({
    mutationFn: (args: EditUserOperationRequest) => api.editUser(args),
  });

  const handleDeleteUser = async () => {
    if (!user) return;
    await userDelete
      .mutateAsync({
        userId: user.id as string,
      })
      .then(() => {
        linkToEmployees();
        queryClient.invalidateQueries({
          queryKey: [CACHE_EMPLOYEES],
        });
      });
  };

  if (isLoading) {
    return (
      <div className="relative h-96 w-full">
        <PageSpinner loadingMessage={t("common:loading")} />
      </div>
    );
  }

  return (
    <PageLayout
      title={user ? user.fullName : ""}
      titleLabel={() => (
        <div className="ml-5 mt-2.5">
          <Badge variant={user?.isActive ? "green" : "red"}>
            {user?.isActive ? t("common:active") : t("common:inactive")}
          </Badge>
        </div>
      )}
      renderActions={() => (
        <ActionButtons
          dropdownClassName="bg-white p-2"
          collapseAt={0}
          actions={[
            {
              Icon: getIcon("sms"),
              label: t("users:resend_welcome_sms.title"),
              confirm: {
                action: "generic",
              },
              isHidden: !user?.isActive,
              onClick: () => resendWelcomeSms.mutateAsync(),
            },
            {
              Icon: getIcon("password"),
              label: t("users:change_password"),
              isHidden: !user?.isActive,
              onClick: () => setPasswordChangeOpen(true),
            },
            {
              Icon: getIcon("deactivateUser"),
              label: t("users:deactivate"),
              onClick: () => handleUserActivation(),
              isHidden: !user?.isActive || user?.id === me?.company.contactPersonId,
            },
            {
              Icon: getIcon("activateUser"),
              label: t("users:activate"),
              onClick: () => handleUserActivation(),
              isHidden: user?.isActive || user?.id === me?.company.contactPersonId,
            },
            {
              Icon: getIcon("delete"),
              label: t("common:delete"),
              confirm: {
                entity: "employee",
                action: "delete",
              },
              onClick: handleDeleteUser,
              isHidden: user?.id === me?.company.contactPersonId,
            },
          ]}
        />
      )}
    >
      <>
        {user && (
          <Dialog
            open={passwordChangeOpen}
            onOpenChange={setPasswordChangeOpen}
            render={({ onClose }) => <ChangeUserPasswordModal onClose={onClose} user={user} />}
          />
        )}
        <PageTabs
          tabs={[
            {
              id: "primary",
              label: t("users:tabs.primary_label"),
              to: "",
            },
            {
              id: "settings",
              label: t("users:tabs.settings"),
              to: "settings/",
            },
          ]}
        />
        <Suspense>{!isLoading && <Outlet context={{ user }} />}</Suspense>
      </>
    </PageLayout>
  );
}
