import { useTranslation } from "react-i18next";
import { decimalToTimeString, padTimeStrings } from "~/lib/utils/date/date-utils";
import { twMerge } from "tailwind-merge";
import { useLocale } from "~/lib/utils/date";
import { WeekSectionProjectForm } from "~/pages/_cmp/weekly-registration-table/form-entry-card";
import { Button, Icon } from "~/lib/ui";
import { linkToFormNew } from "~/lib/utils";
import { Link } from "react-router";

export default function FormEntryCardPopover({
  form,
  showApprovedState,
}: {
  form: WeekSectionProjectForm;
  showApprovedState?: boolean;
}) {
  const { t } = useTranslation();
  const { format } = useLocale();

  // If there is no date on the form, the form is invalid and should not be displayed
  if (!form.date) return null;

  /**
   * Formats the value of an entry based on the time unit
   * @param timeUnit
   * @param value
   * @returns formatted value
   * @example
   * ```
   * formatEntryValue("hours", 8) // 08:00
   * formatEntryValue("km", 10) // 10 km
   * formatEntryValue("daily", 1) // 1 day/dag
   * formatEntryValue("daily", 2) // 2 days/dage
   * ```
   */
  const formatEntryValue = (timeUnit: string, value: number) => {
    switch (timeUnit) {
      case "km":
        return `${value} ${timeUnit}`;
      case "daily":
        return `${value} ${t("common:day", { count: value }).toLowerCase()}`;
      case "hours":
      default:
        return decimalToTimeString(value);
    }
  };

  const formData = [
    { label: t("common:date"), value: format(form.date, { shortDate: true }) },
    {
      label: t("common:time_interval"),
      value: `${padTimeStrings(form.startTime)} - ${padTimeStrings(form.endTime)}`,
    },
    { label: t("common:break_time"), value: padTimeStrings(form.pause) },
    ...form.entries.map((entry) => ({
      label: entry.entryType,
      value: formatEntryValue(entry.timeUnit, entry.total),
    })),
  ];

  if (showApprovedState) {
    formData.push({
      label: t("common:approved"),
      value: form.approved ? t("common:yes") : t("common:no"),
    });
  }

  return (
    <div className="flex min-w-40 flex-col gap-2">
      <div className="flex items-center justify-between gap-8">
        <div className="text-base font-semibold">{t("common:details")}</div>
        <div>
          <Link to={linkToFormNew(form.id)} target="_blank">
            <Button variant="secondary" className="flex items-center gap-2" size="small">
              <span>
                {t("common:go_to")} {t("common:registration", { count: 1 }).toLowerCase()}
              </span>
              <Icon name="externalLink" className="h-4 w-4" />
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col divide-y border text-base sm:text-sm">
        {formData.map((data, idx) => (
          <div
            key={`form-data-${idx}`}
            className={twMerge("flex justify-between gap-8 px-2 py-1.5", idx % 2 && "bg-shade-50")}
          >
            <div>{data.label}</div>
            <div>{data.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
