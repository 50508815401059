import { useOutletContext } from "react-router";
import {
  DeleteProductVariantsRequest,
  GetMe200ResponseCompanySettingsProductPricesSettingEnum,
  Product,
  ProductPriceTypeEnum,
  ProductVariant,
} from "@apacta/sdk";
import { TabHeading } from "~/lib/ui/tabs/heading";
import { useFormState } from "~/lib/form-state";
import { useTranslation } from "react-i18next";
import { useToasts } from "~/lib/toast/use-toasts";
import Switch from "~/lib/ui/switch";
import { useAPI } from "~/lib/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ProductPopover, { ProductItem } from "~/lib/products/product-popover";
import { Button, getIcon, Icon } from "~/lib/ui";
import { NumberFormatInput } from "~/lib/ui/form-elements/number-format-input";
import { useMe } from "~/lib/auth/use-me";
import { VariantPricingTable } from "./_cmp/buying-option-table";
import {
  calculateContributionRatio,
  contributionRatioFromCostAndPriceMargins,
  displayFloat,
  revenueFromCostAndRatio,
} from "~/lib/utils/finance";
import { useDirtyInputValueHack } from "~/lib/ui/form-elements/hooks/use-input-value";
import { productPricingSchema } from "./pricing-schema";
import { useCallback, useEffect, useMemo } from "react";

export type PricingType = ProductPriceTypeEnum;

export default function PricingProductPage() {
  const { product } = useOutletContext<{ product: Product }>();
  const { t } = useTranslation();
  const api = useAPI();
  const me = useMe();
  const toast = useToasts();
  const queryClient = useQueryClient();

  // This is different from the product specific one. We hide and change behavior from this.
  const isCompanyPricingManual =
    me.companySettings.productPricesSetting ===
    GetMe200ResponseCompanySettingsProductPricesSettingEnum.ManuelPrisstning;

  const productSelectedVariant = product.variants?.find((v) => v.isPrimaryBuyingOption);
  const cheapestVariant = product.variants?.reduce((acc, curr) => {
    if (curr.latestPrice < acc.latestPrice) return curr;
    return acc;
  }, product.variants[0]);
  const initialValues = useMemo(() => {
    const defaultPriceType =
      product.priceType ??
      me.companySettings.productPricesSetting ===
        GetMe200ResponseCompanySettingsProductPricesSettingEnum.ManuelPrisstning
        ? "manual"
        : "cost_based";
    // Rounding on the frontend seems weird, but product is made aware of it.
    const buyingPrice = product.buyingPrice ? Number(product.buyingPrice.toFixed(2)) : undefined;
    const sellingPrice = product.sellingPrice ? Number(product.sellingPrice.toFixed(2)) : undefined;

    return {
      buyingPrice,
      sellingPrice,
      contributionRatio: calculateContributionRatio(sellingPrice, buyingPrice),
      priceType: product.priceType ?? defaultPriceType,
      variantType: productSelectedVariant?.type,
      variantId: productSelectedVariant?.id,
    } as const;
  }, [product, me.companySettings.productPricesSetting]);

  const { isModified, isValid, setValues, getValues } = useFormState({
    schema: productPricingSchema,
    initialValues,
  });

  const editProductMutation = useMutation({
    mutationFn: async () => {
      await api.editProduct({
        productId: product.id,
        createProductRequest: {
          buyingPrice,
          sellingPrice,
          priceType,
          variantId: variantId ?? null, // Unsets it
          variantType: variantType ?? null, // Unsets it
        },
      });
      // Reset the form after successful save
      setValues(getValues(), true);

      await refreshData();
    },
  });

  const variantDelete = useMutation({
    mutationFn: async (args: DeleteProductVariantsRequest) => {
      await api.deleteProductVariants({ deleteProductVariantsRequest: args });
      await refreshData();
    },
    onError: () => {
      toast.show({
        title: t("products:variant_delete_error_title"),
        description: t("products:variant_delete_error_description"),
        Icon: getIcon("warningCircle"),
        variant: "error",
        timeout: 5000,
      });
    },
  });

  const variantAdd = useMutation({
    mutationFn: async ({ type, vid }: { vid: string; type: typeof variantType }) => {
      await api.addVariant({
        addVariantRequest: {
          productId: product.id,
          expenseLineId: type === "expense_line" ? vid : undefined,
          vendorProductId: type === "vendor_product" ? vid : undefined,
        },
      });
      await refreshData();
    },
  });

  const productVariants = product.variants ?? [];
  const { buyingPrice, sellingPrice, priceType, contributionRatio, variantId, variantType } =
    getValues();
  const buyingPriceRef = useDirtyInputValueHack(buyingPrice);
  const sellingPriceRef = useDirtyInputValueHack(sellingPrice);
  const contributionRatioRef = useDirtyInputValueHack(contributionRatio);

  const isBuyingPriceLocked = useMemo(() => {
    if (priceType === "manual" || isCompanyPricingManual) {
      return false;
    }
    if (productVariants.length > 0) {
      return true;
    }
    return false;
  }, [priceType, productVariants]);

  const isSellingFieldLocked = useMemo(() => {
    if (priceType === "cost_based") {
      return true;
    }

    // Manually priced product should have the freedom to change
    // -Discussed between AOS/ISM
    return false;
  }, [priceType]);

  const isRatioFieldLocked = useMemo(() => {
    if (priceType === "cost_based") {
      return true;
    }
    return false;
  }, [priceType]);

  async function refreshData() {
    await queryClient.invalidateQueries({
      queryKey: ["product", product.id],
    });
  }

  // If the form isn't modified, but the product has changed, we'll update the form for convenience
  useEffect(() => {
    if (isModified) {
      console.warn("Wanted to update form from product, but form is modified");
      return;
    }
    // Note: Maybe check if the values are the same, but for practical purposes, the isModified should handle that
    setValues(initialValues, true);
  }, [product]);

  const handlePriceTypeChange = useCallback(
    (checked: boolean): void => {
      // If this flips to cost-based we need to recalculate
      const newPriceType = checked ? "manual" : "cost_based";
      if (newPriceType === "cost_based") {
        // If we're missing a variant in cost-based, we have to pick one for them
        handleCostPriceChange(buyingPrice, { newPriceType, newSelectedVariant: cheapestVariant });
      } else {
        // Manual pricing set
        setValues({ priceType: newPriceType });
      }
    },
    [buyingPrice, cheapestVariant]
  );

  const handleContributionRatioChange = useCallback(
    (newRatio: number = 0): void => {
      if (newRatio >= 100) newRatio = 99;
      const newSellingPrice = revenueFromCostAndRatio(buyingPrice, newRatio);
      setValues({
        sellingPrice: newSellingPrice,
        contributionRatio: newRatio,
      });
    },
    [buyingPrice]
  );

  const handleSellingPriceChange = useCallback(
    (newSalesPrice: number = 0): void => {
      setValues({
        sellingPrice: newSalesPrice,
        contributionRatio: calculateContributionRatio(newSalesPrice, buyingPrice),
      });
    },
    [buyingPrice]
  );

  // Handle Cost Price Change. This should adjust the selling price and contribution ratio
  // - Can also be called from a variant-change (then the variant is included)
  const handleCostPriceChange = useCallback(
    (
      newCostPrice: number = 0,
      options?: {
        // Reasons for the change. Defaults to input change
        newSelectedVariant?: ProductVariant;
        newPriceType?: PricingType;
      }
    ): void => {
      // When someone flips the switch to cost-based, we need to adjust everything
      if (options?.newSelectedVariant && options.newPriceType === "cost_based") {
        newCostPrice = options.newSelectedVariant.latestPrice;
      }
      // Variant specific logic
      const additionalOptions = (() => {
        const oVariant = options?.newSelectedVariant;
        const oPriceType = options?.newPriceType ?? priceType;
        // TODO: If new variant is specified, we need to change the newCostPrice
        // No variant changes
        if (oPriceType === "manual") {
          // Changing cost-price manually will unassociate the variant (unless specified)
          return {
            variantId: oVariant?.id,
            variantType: oVariant?.type,
            priceType: oPriceType,
          };
        } else {
          // Cost-based
          return {
            variantType: oVariant?.type, // unchanged unless specified
            variantId: oVariant?.id, // unchanged unless specified,
            priceType: oPriceType,
          }; // No changes to variant
        }
      })();
      if (priceType === "manual") {
        setValues({
          buyingPrice: newCostPrice,
          // retain sales-price and adjust ratio (discussed with ism)
          contributionRatio: calculateContributionRatio(sellingPrice, newCostPrice),
          ...additionalOptions,
        });
      } else {
        // Find price-margin from new cost price (price margins)
        // TODO: We should warn if no price margin is found
        const ratio = contributionRatioFromCostAndPriceMargins(newCostPrice, me.priceMargins);
        setValues({
          buyingPrice: displayFloat(newCostPrice),
          sellingPrice: revenueFromCostAndRatio(newCostPrice, ratio),
          contributionRatio: ratio,
          ...additionalOptions,
        });
      }
    },
    [priceType, sellingPrice, me.priceMargins]
  );

  const handleDeleteVariant = async (productVariant: ProductVariant) => {
    if (!productVariant.id) return;
    const vt = productVariant.type === "expense_line" ? "expenseLines" : "vendorProducts";
    await variantDelete.mutateAsync({
      productId: product.id,
      [vt]: [productVariant.id],
    });
    refreshData();
  };

  const handleBuyingOptionChange = function (newSelectedVariant: ProductVariant | undefined) {
    if (newSelectedVariant === undefined) {
      // This should only happen if we're unsetting the variant
      setValues({
        variantType: undefined,
        variantId: undefined,
      });
      return;
    }
    const latestPrice = newSelectedVariant.latestPrice;
    handleCostPriceChange(latestPrice, { newSelectedVariant });
  };

  // This should add the new product, refresh the data
  // - but for some reason we're COMMITTING this change directly to the product
  async function handleAddVariant(productItem: ProductItem) {
    // TODO: Adding a variant, creates an association - it should NOT happen!
    if (productItem.type === "product") return; // Should not happen
    await variantAdd.mutateAsync({ type: productItem.type, vid: productItem.item.id });
    await refreshData();
  }

  return (
    <>
      <TabHeading
        actionArea={
          <Button
            disabled={!isModified || !isValid}
            variant="tertiary"
            tabIndex={0}
            onClick={() => editProductMutation.mutateAsync()}
          >
            {t("common:save")}
          </Button>
        }
      >
        {t("products:pricing")}
      </TabHeading>
      <div className="flex-1">
        <span className="mb-2 inline-flex items-center gap-2 text-gray-500">
          {t("projects:pricing")}
          {variantId && (
            <span title={t("products:associated_price_variant", "Associated price variant")}>
              <Icon name="link" />
            </span>
          )}
        </span>
        <div className="flex flex-col gap-6 bg-white p-4 shadow sm:gap-8 sm:rounded-lg md:flex-row">
          <div className="flex flex-1 flex-col gap-6">
            <div className="bg-whitemd:flex-row fle x-col flex gap-6">
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  label={t("products:cost_price")}
                  className="text-right text-sm"
                  ref={buyingPriceRef}
                  defaultValue={buyingPrice}
                  onChange={isBuyingPriceLocked ? undefined : (v) => handleCostPriceChange(v)}
                  readOnly={isBuyingPriceLocked}
                  maximumDecimals={2}
                />
                {!isCompanyPricingManual && (
                  <div className="flex-1 pb-4">
                    <Switch
                      controlled
                      label={t("products:manual_selling_price_label")}
                      checked={priceType === "manual"}
                      onCheckedChange={handlePriceTypeChange}
                      className="justify-between sm:justify-start"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  ref={sellingPriceRef}
                  label={t("products:selling_price")}
                  className="text-right text-sm"
                  name="edit_selling_price"
                  defaultValue={sellingPrice}
                  onChange={handleSellingPriceChange}
                  readOnly={isSellingFieldLocked}
                />{" "}
              </div>
              <div className="w-1/16 pt-8 text-center">{t("common:or", "eller")}</div>
              <div className="flex flex-1 flex-col gap-1 md:basis-1/3">
                <NumberFormatInput
                  ref={contributionRatioRef}
                  defaultValue={contributionRatio}
                  label={t("common:contribution_ratio")}
                  className="text-right text-sm"
                  onChange={handleContributionRatioChange}
                  readOnly={isRatioFieldLocked}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row justify-end gap-2">
          <ProductPopover
            triggerRender={() => (
              <Button variant="secondary" className="flex items-center gap-3" Icon={getIcon("add")}>
                {t("products:variant.add.title")}
              </Button>
            )}
            onSelect={(item) => {
              handleAddVariant(item);
            }}
            options={{ allowedProductTypes: ["expense_line", "vendor_product"] }}
          />
        </div>
        <div className="-mb-5 text-gray-500">{t("common:product_variant", { count: 2 })}</div>
        <VariantPricingTable
          priceType={priceType}
          productVariants={productVariants}
          selectedVariantId={variantId}
          onToggleBuyingOption={handleBuyingOptionChange}
          onDelete={handleDeleteVariant}
        />
      </div>
    </>
  );
}
