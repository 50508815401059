import { forwardRef, HTMLProps, Ref, useId } from "react";
import { Input } from "./input";
import { Label } from "../label";
import { twMerge } from "tailwind-merge";

export type LabelInputProps = HTMLProps<HTMLInputElement> & {
  label?: string;
  labelClassName?: string;
  error?: string;
  containerClassName?: string;
};

/**
 * A combination of a label and an input field. Use this if you want to add a label to an input field.
 */
export const LabelInput = forwardRef(function LabelInputInner(
  props: LabelInputProps,
  inputRef: Ref<HTMLInputElement>
) {
  const id = useId();
  const { label, labelClassName, required, containerClassName, ...restProps } = props;
  const error = props.error;

  return (
    <div className={twMerge(containerClassName)}>
      {label && (
        <Label htmlFor={id} required={required} className={labelClassName}>
          {label}
        </Label>
      )}
      <Input
        id={id}
        {...restProps}
        ref={inputRef}
        className={twMerge(error && "border-error", props.className)}
      />
      {props.error ? <p className="mt-1 text-left text-sm text-red-600">{props.error}</p> : null}
    </div>
  );
});
