import { useQuery } from "@tanstack/react-query";
import { DataTable, useDataColumns, useDataTable } from "~/lib/ui/data-table";
import { toLocalDateString, useLocale } from "~/lib/utils/date";
import { Badge } from "~/lib/ui/badge";
import { useDataTableState } from "~/lib/ui/data-table/use-data-table-state";
import { Pagination_Direction, useGraphQL } from "~/lib/gql";
import LazyImage from "~/lib/ui/lazy-image";
import {
  Order_Complaint_Error_Type,
  Order_Complaint_List_Sort_Fields,
  Order_Complaint_Status,
} from "~/lib/gql/generated/graphql";
import { OptionalLink } from "~/lib/utils/routing/optional-link";
import { useTranslation } from "react-i18next";
import { Button, getIcon, Label } from "~/lib/ui";
import { ProcurementPageSearchParams } from "~/pages/procurement";
import { useTypedSearchParams } from "~/lib/utils/use-typed-search-params";
import { linkToExpense, linkToProject } from "~/lib/utils";
import { Link } from "react-router";
import { ProjectSelection } from "~/lib/ui/selection-combobox/project-selection";
import { SelectionCombobox } from "~/lib/ui/selection-combobox";
import OrderComplaintStatus from "~/pages/procurement/index/_cmp/order-complaint-status";

type OrderComplaintsSearchParams = {
  errorType?: Array<Order_Complaint_Error_Type>;
  status?: Array<Order_Complaint_Status>;
  projectIds?: Array<string>;
};

export const CACHE_ORDER_COMPLAINTS = "order-complaints";

export function OrderComplaintsTable({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) {
  const sdk = useGraphQL();
  const { format } = useLocale();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useTypedSearchParams<
    ProcurementPageSearchParams & OrderComplaintsSearchParams
  >();

  const tableState = useDataTableState({
    sorting: [
      {
        desc: !!Pagination_Direction.Desc,
        id: "DeliveryDate",
      },
    ],
  });

  const dataQ = useQuery({
    queryKey: [
      CACHE_ORDER_COMPLAINTS,
      searchParams.companiesVendorIds,
      searchParams.errorType,
      searchParams.status,
      searchParams.projectIds,
      dateFrom,
      dateTo,
    ],
    queryFn: () =>
      sdk.orderComplaintsIndex({
        params: {
          direction: Pagination_Direction.Desc,
          sortBy: Order_Complaint_List_Sort_Fields.DeliveryDate,
          dateInterval: {
            from: toLocalDateString(dateFrom),
            to: toLocalDateString(dateTo),
          },
          companiesVendorIds: searchParams.companiesVendorIds,
          errorTypes: searchParams.errorType,
          statuses: searchParams.status,
          projectIds: searchParams.projectIds,
        },
      }),
  });

  const data = dataQ.data?.orderComplaints.edges ?? [];

  const columns = useDataColumns<(typeof data)[number]>((columnHelper) => [
    columnHelper.accessor("node.expense.vendor.name", {
      header: t("common:vendor", { count: 1 }),
      cell: ({ row }) => (
        <div className="flex items-center gap-4">
          {row.original.node.expense?.vendor?.imageUrl ? (
            <LazyImage
              src={row.original.node.expense.vendor.imageUrl}
              alt={row.original.node.expense.vendor.name ?? ""}
              className="h-auto w-16"
            />
          ) : (
            <img
              src="/image_not_found.jpg"
              alt={row.original.node.expense?.vendor?.name ?? ""}
              className="h-16 w-16"
            />
          )}
          <div>{row.original.node.expense?.vendor?.name}</div>
        </div>
      ),
    }),
    columnHelper.accessor("node.expense.project.fullName", {
      header: t("common:project", { count: 1 }),
      cell: ({ row }) => (
        <OptionalLink
          to={
            !!row.original.node.expense?.project?.id
              ? linkToProject(row.original.node.expense?.project?.id)
              : undefined
          }
        >
          {row.original.node.expense?.project?.fullName}
        </OptionalLink>
      ),
    }),
    columnHelper.accessor("node.expense.supplierInvoiceNumber", {
      header: `${t("common:invoice", { count: 1 })} ${t("invoices:invoice_number").toLowerCase()}`,
      cell: ({ getValue }) => (
        <OptionalLink to={!!getValue() ? linkToExpense(getValue()) : undefined}>
          {getValue()}
        </OptionalLink>
      ),
    }),
    columnHelper.accessor("node.expense.issuedDate", {
      id: "DeliveryDate",
      header: t("expenses:delivery_date"),
      cell: ({ getValue }) => format(getValue(), { shortDate: true }),
    }),
    columnHelper.accessor("node.errorType", {
      header: t("common:error", { count: 2 }),
      cell: ({ row }) => (
        <Badge variant="gray" className="whitespace-nowrap text-xs">
          {t(`procurement:order_complaints.error.${row.original.node.errorType ?? "unknown"}`)}
        </Badge>
      ),
    }),
    columnHelper.accessor("node.status", {
      header: t("common:status"),
      cell: ({ row }) => (
        <OrderComplaintStatus
          defaultValue={row.original.node.status}
          orderComplaintId={row.original.node.id}
        />
      ),
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      meta: {
        className: "text-right",
      },
      cell: ({ row }) => (
        <div className="flex gap-2">
          <Link to={row.original.node.id}>
            <Button as="span" Icon={getIcon("view")} variant="secondary" size="small" />
          </Link>
          <a href={row.original.node.pdfUrl} download>
            <Button as="span" Icon={getIcon("download")} variant="secondary" size="small" />
          </a>
        </div>
      ),
    }),
  ]);

  const table = useDataTable(
    {
      columns,
      tableState,
      data,
      isLoading: dataQ.isFetching,
      getRowId: (row) => row.node.id,
    },
    { enableGlobalFilter: false, enableFilters: true }
  );

  return (
    <DataTable
      table={table}
      renderFilters={() => (
        <div className="flex gap-6">
          <div>
            <ProjectSelection
              popover={{ config: { align: "start" } }}
              requestOverride={{
                projectStatus: [],
              }}
              multiple
              onSelectValues={(values) =>
                setSearchParams("projectIds", values.length ? values : undefined)
              }
            />
          </div>
          <div>
            <OrderComplaintErrorTypeSelection />
          </div>
          <div>
            <OrderComplaintStatusSelection />
          </div>
        </div>
      )}
    />
  );
}

const OrderComplaintErrorTypeSelection = () => {
  const [searchParams, setSearchParams] = useTypedSearchParams<OrderComplaintsSearchParams>();
  const { t } = useTranslation();
  const data = Object.values(Order_Complaint_Error_Type)
    .map((value) => value)
    .filter((v) => v !== "multiple_errors");

  return (
    <div>
      <Label>{t("common:error_type", { count: 2 })}</Label>
      <SelectionCombobox
        multiple
        data={data}
        defaultValue={searchParams.errorType}
        onSelectValues={(values) =>
          setSearchParams(
            "errorType",
            values.length ? values.map((v) => v as Order_Complaint_Error_Type) : undefined
          )
        }
        popover={{
          config: {
            align: "start",
          },
        }}
        valueFn={(v) => v}
        labelFn={(v) => t(`procurement:order_complaints.error.${v ?? "unknown"}`)}
      />
    </div>
  );
};

const OrderComplaintStatusSelection = () => {
  const [searchParams, setSearchParams] = useTypedSearchParams<OrderComplaintsSearchParams>();
  const { t } = useTranslation();

  const data = Object.values(Order_Complaint_Status).map((value) => value);

  return (
    <div>
      <Label>{t("common:status", { count: 2 })}</Label>
      <SelectionCombobox
        multiple
        data={data}
        defaultValue={searchParams.status}
        onSelectValues={(values) =>
          setSearchParams(
            "status",
            values.length ? values.map((v) => v as Order_Complaint_Status) : undefined
          )
        }
        popover={{
          config: {
            align: "start",
          },
        }}
        valueFn={(v) => v}
        labelFn={(v) => t(`procurement:order_complaints.status.${v ?? "unknown"}`)}
      />
    </div>
  );
};
