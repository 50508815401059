import { PageLayout } from "~/lib/ui/page-layout";
import KpiSection from "~/pages/procurement/price-files/_cmp/kpi-section";
import PriceFileAnalysisTable from "~/pages/procurement/price-files/_cmp/price-file-analysis-table";
import { useParams } from "react-router";
import { Button, getIcon } from "~/lib/ui";
import { useTranslation } from "react-i18next";
import PreviousFilesSection from "~/pages/procurement/price-files/_cmp/previous-files-section";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useGraphQL } from "~/lib/gql";
import LazyImage from "~/lib/ui/lazy-image";
import { Badge } from "~/lib/ui/badge";
import { useLocale } from "~/lib/utils/date";
import { Suspense } from "react";

export const CACHE_PRICE_FILES = "price-files";

export default function PriceFileId() {
  const { priceFileId } = useParams<{ priceFileId: string }>();
  const sdk = useGraphQL();
  const { t } = useTranslation();
  const { format } = useLocale();

  const priceFileQ = useSuspenseQuery({
    queryKey: [CACHE_PRICE_FILES, priceFileId],
    queryFn: async () =>
      sdk.priceFileDetails({
        params: {
          id: priceFileId as string,
        },
      }),
  });

  const downloadUrl = priceFileQ.data?.vendorProductPriceFile.downloadUrl;
  const vendorName = priceFileQ.data?.vendorProductPriceFile.companiesVendor.vendorName;
  const vendorLogo = priceFileQ.data?.vendorProductPriceFile.companiesVendor.vendor.imageUrl;
  const currentPriceFileDate = priceFileQ.data?.vendorProductPriceFile.created;

  return (
    <PageLayout
      title={t("common:price_file", { count: 1 })}
      renderActions={() => (
        <a href={downloadUrl}>
          <Button
            as="span"
            loading={priceFileQ.isFetching}
            disabled={priceFileQ.isFetching || !downloadUrl}
            Icon={getIcon("download")}
            variant="secondary"
          >
            {`${t("common:download")} ${t("common:price_file", { count: 1 }).toLowerCase()}`}
          </Button>
        </a>
      )}
      renderDescription={() => (
        <Suspense>
          <div className="flex flex-col items-start gap-2">
            <LazyImage src={vendorLogo} alt="" previewSize={8} />
            <div>{vendorName ?? t("common:unknown")}</div>
            <Badge variant="white">
              {currentPriceFileDate
                ? format(currentPriceFileDate, { shortDate: true })
                : t("common:not_available")}
            </Badge>
          </div>
        </Suspense>
      )}
    >
      <div className="flex flex-col gap-6">
        <KpiSection />
        <PreviousFilesSection />
        <PriceFileAnalysisTable />
      </div>
    </PageLayout>
  );
}
