import { DialogHeader } from "~/lib/ui/dialog/dialog-header";
import { DialogFooter } from "~/lib/ui/dialog/dialog-footer";
import { useMutation } from "@tanstack/react-query";
import { useAPI } from "~/lib/api";
import { useFormState } from "~/lib/form-state";
import { z } from "zod";
import TextArea from "~/lib/ui/form-elements/textarea";
import { useTranslation } from "react-i18next";
import { IOfferSendEmailRequest, Offer } from "@apacta/sdk";
import { RefObject, useState } from "react";
import { useToasts } from "~/lib/toast/use-toasts";
import { runOfferLineRule } from "~/pages/offers/_cmp/helpers/run-offer-line-rules";
import { OrderLinesBuilderRef } from "~/lib/ui/order-lines/lib/types";
import { OfferFormState } from "~/pages/offers/_cmp/state/use-offer-form-state";
import { getIcon, Icon, LabelInput } from "~/lib/ui";
import { emailRequired } from "~/lib/form-state/zod";

const SendOfferFormStateSchema = {
  recipientEmail: emailRequired(),
  subject: z.string().min(1),
  body: z.string().min(1),
};
export default function SendOfferModal({
  offer,
  formState,
  orderLinesBuilderRef,
  getPdf,
  onClose,
  isModified,
  saveFn,
  initialEmail,
  initialSubject,
  initialBody,
}: {
  offer: Offer;
  formState: OfferFormState;
  orderLinesBuilderRef: RefObject<OrderLinesBuilderRef | null>;
  getPdf: () => Promise<Blob | void>;
  onClose: () => void;
  isModified?: boolean;
  saveFn?: () => Promise<void>;
  initialEmail?: string;
  initialSubject?: string;
  initialBody?: string;
}) {
  const api = useAPI();
  const { t } = useTranslation();
  const [sending, setSending] = useState<boolean>(false);

  const [hasSaved, setHasSaved] = useState<boolean>(false);

  const { show: addToast } = useToasts();

  const modalFormState = useFormState({
    schema: SendOfferFormStateSchema,
    initialValues: {
      recipientEmail: initialEmail ?? "",
      subject: initialSubject ?? "",
      body: initialBody ?? "",
    },
  });

  const sendOfferMutation = useMutation({
    mutationFn: async (data: IOfferSendEmailRequest) => api.iOfferSendEmail(data),
    onSettled: (data, error) => {
      setSending(false);
      if (!error) {
        onClose?.();
      }
    },
  });

  const handleSendOffer = async () => {
    setSending(true);

    // Save changes if we have any. User is alerted to this in the modal.
    if (isModified && !hasSaved) {
      await saveFn?.();
      setHasSaved(true);
    }

    // Create and get the PDF blob
    await runOfferLineRule(offer, orderLinesBuilderRef, formState, api);
    const pdf = await getPdf();

    if (pdf) {
      sendOfferMutation.mutate({
        offerId: offer.id,
        body: modalFormState.getValue("body"),
        subject: modalFormState.getValue("subject"),
        recipientEmail: modalFormState.getValue("recipientEmail"),
        pdf,
      });
    } else {
      setSending(false);
      addToast({
        variant: "error",
        title: t("offers:send_offer_modal.error_toast.title"),
        description: t("offers:send_offer_modal.error_toast.description"),
        Icon: getIcon("warningCircle"),
      });
    }
  };

  return (
    <div>
      <DialogHeader title={t("offers:send_offer_modal.title")} Icon={getIcon("send")} />
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div>
            <Icon name="warningTriangle" className="h-8 w-8 text-orange-600" />
          </div>
          <div className="whitespace-pre-line">{t("offers:send_offer_modal.description")}</div>
        </div>
        <div className="flex flex-col gap-4">
          <LabelInput
            {...modalFormState.registerStringInput("recipientEmail")}
            label={t("common:email")}
            type="email"
          />
          <LabelInput
            {...modalFormState.registerStringInput("subject")}
            label={t("common:subject")}
          />
          <TextArea
            initialValue={modalFormState.getValue("body")}
            onChange={(s) => modalFormState.setValues({ body: s })}
            label={t("common:message")}
            fullHeight={true}
            className="h-36"
            required={true}
          />
        </div>
      </div>
      <DialogFooter
        primary={{
          label: t("common:send"),
          onClick: handleSendOffer,
          loading: sending,
          disabled: sending || !modalFormState.isValid,
        }}
        onClose={onClose}
      />
    </div>
  );
}
